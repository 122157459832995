import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import {apiConfig, axiosInstance} from "../../apiConfig";
import "./Requestmoney.css";

import { Network } from '@capacitor/network';

const Requestmoney: React.FC = () => {
  const history = useHistory();
  const [unit, setUnit] = useState<string>();
  const goBack = () => {
    history.goBack();
    latestInputOldPassValue.current = "";
    latestInputNewPassValue.current = "";
    latestInputCnfmPassValue.current = "";
    setInputOldPass('');
    setInputNewPass('');
    setInputCnfmPass('');
    setCheckCnfmPassError('');
  };
  const [inputOldPass, setInputOldPass] = useState('');
  const latestInputOldPassValue = useRef('');

  const [inputNewPass, setInputNewPass] = useState('');
  const latestInputNewPassValue = useRef('');

  const [inputCnfmPass, setInputCnfmPass] = useState('');
  const latestInputCnfmPassValue = useRef('');

  const [checkCnfmPassError, setCheckCnfmPassError] = useState('');

  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');
  const [showSuspendedModal, setShowSuspendedModal] = useState(false);

  const [showToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [showToasSuccess, setShowToastSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [isDarkModeActive, setIsDarkModeActive] = useState(Boolean);

  const [formState, setFormState] = useState({
    oldPass: '',
    oldPassError: '',
    newPass: '',
    newPassError: '',
    cnfmPass: '',
    cnfmPassError: ''
  });
  const [networkStatus, setNetworkStatus] = useState<any>(true);
  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
    });
  }, []);

  useIonViewWillEnter(() => {
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      history.push('/login');
    }
    console.log("darkModeValue", localStorage.getItem("darkMode"));
    if (localStorage.getItem("darkMode") == "darkMode") {
      setIsDarkModeActive(true);
    } else {
      setIsDarkModeActive(false);
    }
  }, [])

  const handleOldPassInputChanges = (event: any) => {
    const oldPass = event.target.value;
    setInputOldPass(event.target.value);
    latestInputOldPassValue.current = oldPass;
    // const oldPassPattern = /^[a-zA-Z0-9]+$/;
    // if (oldPass && !oldPassPattern.test(oldPass)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     oldPassError: 'Invalid old password'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     oldPassError: ''
    //   }));
    // }
  };
  const handleNewPassInputChanges = (event: any) => {
    const newPass = event.target.value;
    setInputNewPass(event.target.value);
    latestInputNewPassValue.current = newPass;
    if (latestInputNewPassValue.current != inputCnfmPass) {
      setCheckCnfmPassError('New Password and Confirm password does not match');
    } else {
      setCheckCnfmPassError('');
    }
    // const NewPassPattern = /^[a-zA-Z0-9]+$/;
    // if (newPass && !NewPassPattern.test(newPass)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     newPassError: 'Invalid new password'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     newPassError: ''
    //   }));
    // }
  };
  const handleCnfmPassInputChanges = (event: any) => {
    const cnfmPass = event.target.value;
    setInputCnfmPass(event.target.value);
    latestInputCnfmPassValue.current = cnfmPass;
    // const CnfmPassPattern = /^[a-zA-Z0-9]+$/;
    if (latestInputCnfmPassValue.current != inputNewPass) {
      setCheckCnfmPassError('New Password and Confirm password does not match');
    } else {
      setCheckCnfmPassError('');
    }
    // if (cnfmPass && !CnfmPassPattern.test(cnfmPass)) {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     cnfmPassError: 'Invalid email address'
    //   }));
    // } else {
    //   setFormState(prevState => ({
    //     ...prevState,
    //     cnfmPassError: ''
    //   }));
    // }
  };

  const submitChangePass = () => {
    setLoading(true);
    setBackdropVisible(true);
    if (formState.cnfmPassError == '') {
      if (!latestInputOldPassValue.current || !latestInputNewPassValue.current || !latestInputCnfmPassValue.current) {
        setErrorMessage('Please enter a All Passwords');
        setShowErrorToast(true);
      } else {
        const endpoint = "/changePassword"
         axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {
          old_password: latestInputOldPassValue.current,
          new_password: latestInputNewPassValue.current,
          confirm_password: latestInputCnfmPassValue.current
        }, { headers: apiConfig.getHeaders(endpoint) })
          .then(response => {
            if(response.data.message == "User not found") {
              setLoading(false);
              setBackdropVisible(false);
              history.push('/login');
            } else {
            if (response.data.suspendstatus == false) {
              setSupendedMsg(response.data.message);
              setSupendedTitle(response.data.title);
              setShowSuspendedModal(true);
              setLoading(false);
              setBackdropVisible(false);
            } else {
              console.log("response", response.data);
              if (response.data.status == false) {
                setShowErrorToast(true);
                setErrorMessage(response.data.message);
                setLoading(false);
                setBackdropVisible(false);
              } else {
                setShowToastSuccess(true);
                setSuccessMessage(response.data.message);
                latestInputOldPassValue.current = "";
                latestInputNewPassValue.current = "";
                latestInputCnfmPassValue.current = "";
                setInputOldPass('');
                setInputNewPass('');
                setCheckCnfmPassError('');
                setInputCnfmPass('');
                localStorage.setItem("isLoggedIn","no");
                history.push('/login');
                setLoading(false);
                setBackdropVisible(false);
              }

            }
          }
            setTimeout(() => {
              if (loading == true) {
                setLoading(false);
                setBackdropVisible(false);
                // setErrorMessage("Something went wrong on API please try again");
                // setShowToast(true);
              }
            }, 10000);
          })
          .catch(error => {
            console.log(error);
            if (error.code == "ERR_NETWORK") {
              setNetworkStatus(false);
            } else {
              setNetworkStatus(true);
            }
            setLoading(false);
            setBackdropVisible(false);
          });
      }
    } else {
      setErrorMessage('Please enter a valid Password');
      setShowErrorToast(true);
    }
  }

  return (
    <IonPage>
      {networkStatus == false && isDarkModeActive == false &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start" className="back-btn">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center">
                Change Password
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="requestmoney">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == false &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start" className="back-btn">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center">
                Change Password
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="requestmoney">
            <div className="bg-fp"></div>
            <div className="gradient-overlay m-d-none"></div>
            <div className="web-fp">
              <IonImg className="change-password-img" src="assets/imgs/images/changePassword.svg" alt=""></IonImg>
              <IonLabel className="change-password-label">Your new password must be different from previously used password.</IonLabel>

              <div className="change-pass-mt animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model-fp fontName'
                          value={inputOldPass}
                          onChange={e => handleOldPassInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model-fp" htmlFor="textAreaExample">Old Password</label>
                      </div>
                      {formState.oldPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.oldPassError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model-fp fontName'
                          value={inputNewPass}
                          onChange={e => handleNewPassInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model-fp" htmlFor="textAreaExample">New Password</label>
                      </div>
                      {formState.newPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.newPassError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='form-control-model-fp fontName'
                          value={inputCnfmPass}
                          onChange={e => handleCnfmPassInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="label-outline-model-fp" htmlFor="textAreaExample">Confirm Password</label>
                      </div>
                      {/* {formState.cnfmPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.cnfmPassError}</span>} */}
                      <span className='fontName emailErrorMsg'>{checkCnfmPassError}</span>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonButton expand="block" onClick={submitChangePass} className="change-pass-mt submit-fp">
                        Submit
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowErrorToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger" />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success" />
          </IonContent></>
      }
      {networkStatus == false && isDarkModeActive == true &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="dark-mode-header-toolbar">
            <IonButtons slot="start" className="back-btn">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBackArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center dark-ion-title">
              Change Password
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent fullscreen className="dark-profile">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true && isDarkModeActive == true &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="dark-mode-header-toolbar">
            <IonButtons slot="start" className="back-btn">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBackArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center dark-ion-title">
              Change Password
            </IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/notifications"
                routerDirection="none"
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBell.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent fullscreen className="dark-profile">
            <div className="bg-fp"></div>
            <div className="gradient-overlay m-d-none"></div>
            <div className="dark-web-fp">
              <IonImg className="change-password-img" src="assets/imgs/images/darkChangePassword.svg" alt=""></IonImg>
              <IonLabel className="dark-change-password-label">Your new password must be different from previously used password.</IonLabel>

              <div className="change-pass-mt animate__animated animate__bounceIn ion-padding-horizontal ion-text-center">
                <IonGrid>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='dark-form-control-model-fp fontName'
                          value={inputOldPass}
                          onChange={e => handleOldPassInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="dark-label-outline-model-fp" htmlFor="textAreaExample">Old Password</label>
                      </div>
                      {formState.oldPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.oldPassError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='dark-form-control-model-fp fontName'
                          value={inputNewPass}
                          onChange={e => handleNewPassInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="dark-label-outline-model-fp" htmlFor="textAreaExample">New Password</label>
                      </div>
                      {formState.newPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.newPassError}</span>}
                    </IonCol>
                    <IonCol size="12" col-12>
                      <div className="model-container">
                        <input
                          className='dark-form-control-model-fp fontName'
                          value={inputCnfmPass}
                          onChange={e => handleCnfmPassInputChanges(e)}
                          onFocus={(e) => e.preventDefault()}
                        ></input>
                        <label className="dark-label-outline-model-fp" htmlFor="textAreaExample">Confirm Password</label>
                      </div>
                      {/* {formState.cnfmPassError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.cnfmPassError}</span>} */}
                      <span className='fontName emailErrorMsg'>{checkCnfmPassError}</span>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonButton expand="block" onClick={submitChangePass} className="change-pass-mt submit-fp">
                        Submit
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowErrorToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger" />
            <IonToast
              isOpen={showToasSuccess}
              onDidDismiss={() => setShowToastSuccess(false)}
              message={successMessage}
              duration={3000}
              color="success" />
          </IonContent></>
      }
    </IonPage>
  );
};

export default Requestmoney;
