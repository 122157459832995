import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const MetaManager: React.FC = () => {
  const location = useLocation();

  // Default meta tags
  let title = "Partner Centre | Anything for Hire";
  let description = "Online application form to submit your hire company to Anything for Hire. Join UK's biggest marketplace, dedicated to the hire industry and grow your business. Get your free business tools for your hire company.";
  let keywords = "join anything for hire, signup to anything for hire, afh signup, marketplace, online price comparison website, booking software for hire companies";

  // Route-specific meta tags
  if (location.pathname === "/Getstarted") {
    title = "Partner Centre | Submit Your Hire Company or Login";
    description ="Grow your hire business by joining the UK's #1 platform for hire companies. Anything For Hire marketplace connects customers with local hire companies. Allowing them to compare and hire services all from one convenient place.";
    keywords = "hire, rental, anything for hire, compare hire companies, hire company, local hire company, hire services, hire anything";
  } else if (location.pathname === "/login") {
    title = "Anything for Hire Partner Login | Access Your Dashboard";
    description = "Log in to your Anything for Hire Partner account to manage bookings, update your services, and connect with customers. Stay organized and grow your business effortlessly.";
    keywords = "hire, rental, anything for hire, compare hire companies, hire company, local hire company, hire services, hire anything";
  } else if (location.pathname === "/sign-up") {
    title = "Join the Anything for Hire Partner Network | Sign Up Today";
    description = "Become an Anything for Hire Partner and reach a wider audience. Sign up now to offer your services, manage bookings, and grow your business with ease.";
    keywords = "hire, rental, anything for hire, compare hire companies, hire company, local hire company, hire services, hire anything";
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
};

export default MetaManager;
