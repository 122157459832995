import axios from 'axios';
import { useHistory } from 'react-router-dom';

const apiConfig = {
  
   // baseUrl: 'https://aggregator.co.uk/api/partner',
  // accessToken
  // baseUrl: 'https://afh.cloud/api/partner',
  baseUrl: 'https://afh.cloud/api/provider',

  getHeaders: (endpoint: string) => {
    // Customize headers for specific endpoints
    if (endpoint === '/login' || endpoint === '/signup' || endpoint === '/auto_login') {
      return {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
    } else if (endpoint === '/profile_dp_update' || endpoint === '/addproduct') {
      return {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data'
      };
    } else {
      return {
        'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      };
    }
  }
};

// Axios instance
const axiosInstance = axios.create({
  baseURL: apiConfig.baseUrl,
});

// Interceptor for handling errors
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.message === "User not found") {
      localStorage.setItem("isLoggedIn", "no");
      const history = useHistory();
      history.push('/login');
    }
    return response;
  },
  (error) => {
    // Handle error responses
    if (error.response && error.response.status === 401) {
      localStorage.setItem("isLoggedIn", "no");
      const history = useHistory();
      history.push('/login');
    }
    return Promise.reject(error);
  }
);

export { apiConfig, axiosInstance };