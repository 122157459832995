import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRouterLink,
  IonRow,
  IonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import axios from "axios";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {apiConfig, axiosInstance} from "../../apiConfig";
import { App as CapacitorApp } from '@capacitor/app';
import "./Login.css";

import { Network } from '@capacitor/network';

import messaging from '../../Firebase';
import { getToken, isSupported, onMessage } from 'firebase/messaging';

import { getPlatforms } from '@ionic/react';
import { PushNotifications } from "@capacitor/push-notifications";
import { Link } from "react-router-dom";

const Login: React.FC = () => {
  const history = useHistory();
  const darkModeValue = localStorage.getItem("darkMode");
  
  const [showBackAlert, setShowBackAlert] = useState(false);
  const [rememberStatus, setRememberStatus] = useState(Number);
  const [formState, setFormState] = useState({
    email: '',
    password: '',
    emailError: '',
    passwordError: '',
  });
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [iserror, setIserror] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [inputEmail, setInputEmail] = useState('');
  const latestInputEmailValue = useRef('');
  const latestInputPasswordValue = useRef('');
  const [inputPassword, setInputPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const [notificationTap, setNotificationTap] = useState(false);
  const [bookingId, setBookingId] = useState('');


  const [networkStatus, setNetworkStatus] = useState<any>(true);
  function getPlatforms() {
    // Simple check for desktop or mobile platform
    if (navigator.userAgent.indexOf('Mobi') > -1) {
      return "mobile";
    } else {
      return "desktop";
    }
  }
  // const [id, setId] = useState<string | null>(null);
 

  useIonViewWillEnter(() => {
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      history.push('/login');
    } else {
      history.push("/tabs/dashboard")
    }
    latestInputEmailValue.current = "";
    latestInputPasswordValue.current = "";
    setInputEmail("");
    setFormState(prevState => ({
      ...prevState,
      emailError: ''
    }));
    setInputPassword("");
  }, []);

  useIonViewWillEnter(() => {
    setShowSuspendedModal(false);
    const searchParams = new URLSearchParams(location.search);
      const paramId = searchParams.get("id");
      if (paramId) {
        // setId(paramId);
        autoLogin(paramId);
      }
    
  }, []);
  const autoLogin = async (currentId:any) => {
    try {
      setLoading(true);
      setBackdropVisible(true);

      const endpoint = "/auto_login";
      const payload: any = { id: currentId };

      const fcmtoken = localStorage.getItem("fcmToken");
      if (fcmtoken) {
          payload.fcmtoken = fcmtoken;
      }

      const res = await axiosInstance.post(
          `${apiConfig.baseUrl}${endpoint}`, 
          payload, 
          { headers: apiConfig.getHeaders(endpoint) }
      );
      if (res.data.suspendstatus === false) {
        setSupendedMsg(res.data.message);
        setSupendedTitle(res.data.title);
        setShowSuspendedModal(true);
      } else if (res.data.status === true) {
        localStorage.setItem("name", res.data.data.name);
        localStorage.setItem("tradingName", res.data.data.ownername);
        localStorage.setItem("companyName", res.data.data.companyname);
        localStorage.setItem("email", res.data.data.email);
        localStorage.setItem("displaypicture", res.data.data.displaypicture);
        localStorage.setItem("mobile", res.data.data.mobile);
        localStorage.setItem("postcode", res.data.data.postcode);
        localStorage.setItem("tokenType", res.data.data.token_type);
        localStorage.setItem("accessToken", res.data.data.accessToken);
        localStorage.setItem("expensesInfo", "info");
        localStorage.setItem("servicesViewed", "active");
        localStorage.setItem("newUser", "yes");
        localStorage.setItem("fromSignup", "no");
        localStorage.setItem("isLoggedIn","yes");
        localStorage.setItem("loggedOut","unsuccesful");
        // setId(null);

        const searchParams = new URLSearchParams(location.search);
        searchParams.delete(location.search);
        window.location.assign("/tabs/dashboard")
        const newUrl = `${location.pathname}`;
        window.history.replaceState(null, newUrl);
  
        // history.push('/tabs/dashboard');
      } else {
        setErrorMessage(res.data.message);
        setShowToast(true);
      }
    } catch (error) {
      console.error("Auto-login error:", error);
      setShowToast(true);
      setMessage("Auth failure! Please create an account");
      setIserror(true);
    } finally {
      setLoading(false);
      setBackdropVisible(false);
    }
  };

  useEffect(() => {

    // This is FCM vapidKey        
    // BLVuQ5xiL78AifOLx31w3iTSlAdMGGP0q-_9TviTzEEKnATHb-9YjZe68LAtdAQdIdtAjVYf6CzAtHm7gvAAaGI

    Network.addListener('networkStatusChange', status => {
        console.log('Network status changed', status.connected);
        setNetworkStatus(status.connected);
    });

    
    const newUser = localStorage.getItem("newUser");    

    if (newUser == "" || newUser == undefined || newUser == null) {
      localStorage.setItem("newUser", "no");
  } else {
      localStorage.setItem("newUser", "yes");
  }

  if (darkModeValue == "darkMode") {
      localStorage.setItem("darkMode", "darkMode");
  } else if (darkModeValue == "" || darkModeValue == null || darkModeValue == undefined) {
      localStorage.setItem("darkMode", "lightMode");
  } else {
      localStorage.setItem("darkMode", "lightMode");
  }

    if (getPlatforms() == "desktop") {

        isSupported().then((supported) => {
            console.log('Messaging support check:', supported);
            if (supported) {
                try {
                    console.log('Firebase Messaging initialized:', messaging);
                    // Request permission to send notifications
                    Notification.requestPermission().then(permission => {
                        if (permission === "granted") {

                            getToken(messaging, { vapidKey: 'BLVuQ5xiL78AifOLx31w3iTSlAdMGGP0q-_9TviTzEEKnATHb-9YjZe68LAtdAQdIdtAjVYf6CzAtHm7gvAAaGI' })
                            .then((token) => {
                                console.log('Token generated:', token);
                                if(token){
                                // handShake(token);
                                localStorage.setItem("fcmToken", token);
                                }
                            })
                            .catch((error) => {
                                console.error('Token generation failed:', error);
                            });
                        } else {
                            console.error("Permission denied for notifications");
                            }
                    });
                } catch (error) {
                    console.error('Failed to initialize Firebase Messaging:', error);
                }
            }
        }).catch((error) => {
            console.error('Error during Firebase Messaging support check:', error);
        });
    }
  }, []);

  useEffect(() => {
    // Add listeners for push notifications
    const initializeListeners = async () => {
        await PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.log('Push notification received: ', notification.body);
            const notifyBody = notification.body;
            const idMatch = notifyBody?.match(/ID: #(\d+)/);
            if (idMatch) {
                const id = idMatch[1]; // Extract booking ID
                console.log('Extracted ID: ', id);
                localStorage.setItem('bookingId', id);
                setBookingId(id);
            } else {
                console.log('ID not found in the notification body.');
                localStorage.setItem('bookingId', '');
            }
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
            console.log('Push notification action performed', notification);
            if (notification.actionId === 'tap') {
                setNotificationTap(true);
                const storedBookingId = localStorage.getItem('bookingId');
                if (storedBookingId) {
                    console.log('Navigating to /viewbookings with ID: ', storedBookingId);
                    history.push('/viewbookings');
                } else {
                    console.log('Navigating to /notifications');
                    history.push('/notifications');
                }
            }
        });
    };

    // initializeListeners();

    // Cleanup listeners on unmount
    // return () => {
    //     PushNotifications.removeAllListeners();
    // };
}, [history, bookingId]);


const handleEmailInputChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
  // Remove spaces from the input value
  const email = event.target.value.replace(/\s/g, ''); // Remove all spaces
  setInputEmail(email);
  latestInputEmailValue.current = email;

  // Email pattern to validate input
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (email && !emailPattern.test(email)) {
    setFormState(prevState => ({
      ...prevState,
      emailError: 'Invalid email address',
    }));
  } else {
    setFormState(prevState => ({
      ...prevState,
      emailError: '',
    }));
  }
};

const preventSpaceInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === ' ') {
    event.preventDefault(); // Prevent space from being typed
  }
};

  const checkboxChanged = (event: any) => {
    console.log('Checkbox state changed:', event.detail.checked);
    if (event.detail.checked == true) {
      setRememberStatus(1);
    } else {
      setRememberStatus(0);
    }
  }


  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (formState.emailError == '') {
      if (!latestInputEmailValue.current || !latestInputPasswordValue.current) {
        setErrorMessage('Please enter a valid email and password');
        setShowToast(true);
      } else {
        setLoading(true);
        setBackdropVisible(true);
        const loginData: { email: string; password: string; fcmtoken?: string } = {
          email: latestInputEmailValue.current,
          password: latestInputPasswordValue.current,
        };
  
        const fcmToken = localStorage.getItem("fcmToken");
        if (fcmToken) {
          loginData.fcmtoken = fcmToken;
        }
        const endpoint = "/login"
         axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, loginData, { headers: apiConfig.getHeaders(endpoint) })
          .then((res: any) => {
            if (res.data.suspendstatus == false) {
              setSupendedMsg(res.data.message);
              setSupendedTitle(res.data.title);
              setShowSuspendedModal(true);
              setLoading(false);
              setBackdropVisible(false);
            } else {
              if (res.data.status == true) {
                localStorage.setItem("name", res.data.data.name);
                localStorage.setItem("tradingName", res.data.data.ownername);
                localStorage.setItem("companyName", res.data.data.companyname);
                localStorage.setItem("email", res.data.data.email);
                localStorage.setItem("displaypicture", res.data.data.displaypicture);
                localStorage.setItem("mobile", res.data.data.mobile);
                localStorage.setItem("postcode", res.data.data.postcode);
                localStorage.setItem("tokenType", res.data.data.token_type);
                localStorage.setItem("accessToken", res.data.data.accessToken);
                localStorage.setItem("isLoggedIn","yes");
                // localStorage.setItem("partnerId",res.data.partner_id);
                localStorage.setItem("expensesInfo", "info");
                localStorage.setItem("servicesViewed","active");
                setLoading(false);
                setBackdropVisible(false);
                setShowPassword(false);
                setInputEmail('');
                setInputPassword('');
                // formState.emailError == '';
                latestInputEmailValue.current = '';
                latestInputPasswordValue.current = '';
                localStorage.setItem("newUser", "yes");
                localStorage.setItem("fromSignup", "no");
                localStorage.setItem("loggedOut","unsuccesful");
                // setId(null);

                const searchParams = new URLSearchParams(location.search);
                searchParams.delete("id");
                const newUrl = `${location.pathname}?${searchParams.toString()}`;
        
                window.history.replaceState(null, "", newUrl);
                // const newUrl = `/tabs/dashboard?${searchParams.toString()}`;
          
                history.push('/tabs/dashboard');
              } else {
                setLoading(false);
                setBackdropVisible(false);
                setErrorMessage(res.data.message);
                setShowToast(true);
              }
            }
            setTimeout(() => {
              if (loading == true) {
                setLoading(false);
                setBackdropVisible(false);
                // setErrorMessage("Something went wrong on API please try again");
                // setShowToast(true);
              }
            }, 10000);
          })
          .catch((error) => {
            if (error.code == "ERR_NETWORK") {
              setNetworkStatus(false);
            } else {
              setNetworkStatus(true);
            }
            setMessage("Auth failure! Please create an account");
            setIserror(true)
          })

      }
    } else {
      setErrorMessage('Please enter a valid email');
      setShowToast(true);
    }

  };
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordInputChange = (event: any) => {
    setInputPassword(event.target.value);
    latestInputPasswordValue.current = event.target.value;
  };

  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" &&
        <div className="no-network-div">
          <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
          <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
        </div>
      }
      {networkStatus == true &&
        darkModeValue == "darkMode" &&
        <IonContent className="login ion-padding-horizontal overflow background-img" fullscreen>
          <div className='wrapper background-img-container'>
            <IonImg src="assets/imgs/images/loginBg.png" alt="bg" className="product-image d-d-none"></IonImg>
            <IonImg src="assets/imgs/images/afh-pro-desktop.jpg" alt="bg" className="product-image"></IonImg>
            <div className="dark-card-container">
              <IonGrid>
                <IonRow>
                  <IonCol col-12 size="12">
                    <IonImg style={{
                      margin: "1vh 0vh 0vh 1vh"
                    }} className="afh-logo login-logo" src="assets/imgs/images/dark-afhLogo.svg" alt="Logo Image"></IonImg>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="m-title login-title " style={{ margin: "1vh" }}>
                      <IonLabel className="dark-wlcm-title">Welcome Back.</IonLabel>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <form onSubmit={handleSubmit}>
                <div className="the-form">
                  <div className="input-holder">
                    <IonLabel className="dark-input-label">Email</IonLabel>
                    <IonItem className="dark-login-ion-item input-item" lines="none">
                      <input
                        type="email"
                        name="email"
                        className='dark-fontName dark-input login-input'
                        placeholder="Enter email"
                        value={inputEmail}
                        onChange={e => handleEmailInputChanges(e)}
                        onKeyDown={(event) => {
                          preventSpaceInput(event);
                          handleKeyPress(event);
                        }}
                      />
                      {/* <IonInput
                    className="form-input"
                    placeholder="Enter Name"
                    type="text"
                  /> */}
                      <IonIcon
                        slot="start"
                        style={{ fontSize: "17px", margin: "0px 10px 0px 0px" }}
                        className="fav"
                        src="assets/imgs/icons/dark-email.svg"
                      />
                    </IonItem>
                  </div>
                  {formState.emailError && <span style={{ marginTop: "-2%" }} className='dark-fontName emailErrorMsg'>{formState.emailError}</span>}
                  <div className="input-holder">
                    <IonLabel className="dark-input-label">Password</IonLabel>
                    <IonItem className="dark-login-ion-item input-item" lines="none">
                      <input
                        type='text'
                        name="password"
                        className={showPassword ? 'dark-input dark-fontName login-input passwordVisible' : 'dark-input dark-fontName login-input passwordHidden'}
                        placeholder="Enter password"
                        value={inputPassword}
                        onChange={handlePasswordInputChange}
                        onKeyDown={handleKeyPress}
                      />
                      <IonIcon
                        slot="start"
                        style={{ fontSize: "20px", margin: "0px 8px 0px 0px" }}
                        className="fav"
                        src="assets/imgs/icons/dark-password.svg"
                      />
                      <IonIcon
                        slot='end'
                        className='dark-eye-icon'
                        onClick={handleTogglePassword}
                        icon={showPassword ? eyeOffOutline : eyeOutline}
                      />
                    </IonItem>
                  </div>
                </div>
                <div className="rem-holder">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <div className="remind-item-div">
                        {/* <IonItem className="rem-item dark-remind-item" lines="none">
                        <IonCheckbox onIonChange={checkboxChanged} className="rem-check" slot="start" mode="ios" />
                        <IonLabel className="dark-rem-label">Remember me</IonLabel>
                      </IonItem> */}
                        {/* <a href='https://partners.anythingforhire.com/password/reset'>
                          <IonLabel className="dark-forgot-password">Forgot Password?</IonLabel>
                        </a> */}
                        <Link to="/password/reset">
                          <IonLabel class="forgot-password">
                            Forgot Password?
                          </IonLabel>
                        </Link>

                      </div>
                    </IonCol>
                  </IonRow>
                </div>

                <div className="btn-holder">
                  <IonButton expand="block" className="login-button" type="submit">
                    Login
                  </IonButton>
                </div>
              </form>
            </div>
          </div>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={errorMessage}
            duration={3000}
            color="danger"
          />
          <IonLoading
            isOpen={loading}
            spinner="circular"
            translucent={true}
          />
          <IonAlert
            isOpen={showBackAlert}
            onDidDismiss={() => setShowBackAlert(false)}
            header={'Exit App'}
            message={'Are you sure you want to exit the app?'}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  setShowBackAlert(false);
                }
              },
              {
                text: 'Exit',
                handler: () => {
                  CapacitorApp.exitApp();
                }
              }
            ]}
          />
          <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
            <IonContent className="dark-model-bg">
              <IonGrid className='cnfmModelGrid'>
                <IonRow>
                  <IonCol size="12" col-12>
                    <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                  </IonCol>
                  <IonCol size='12' col-12>
                    <IonButton
                      routerLink="/login"
                      routerDirection="none"
                      style={{ width: "100%", margin: "auto" }}
                      className='fontName booking-details-btn'
                      onClick={() => setShowSuspendedModal(false)}
                      expand="block">
                      Back to Login
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
        </IonContent>
      }
      {networkStatus == false && darkModeValue == "lightMode" &&
        <div className="no-network-div">
          <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
          <h6 className='fontName'>Check your internet connection and try again</h6>
        </div>
      }
      {networkStatus == true && darkModeValue == "lightMode" &&
        <IonContent className="login ion-padding-horizontal overflow background-img" fullscreen>
          <div className='wrapper background-img-container'>
            <IonImg src="assets/imgs/images/loginBg.png" alt="bg" className="product-image d-d-none"></IonImg>
            <img src="assets/imgs/images/afh-pro-desktop.jpg" alt="bg" className="product-image"></img>
        <div className="gradient-overlay t-d-none m-d-none"></div>
            <div className="login-card-container">
              <IonGrid>
                <IonRow>
                  <IonCol col-12 size="12">
                    <IonImg style={{
                      margin: "1vh 0vh 0vh 1vh"
                    }} className="afh-logo login-logo" src="assets/imgs/images/afhLogo.svg" alt="Logo Image"></IonImg>
                  </IonCol>
                  <IonCol col-12 size="12">
                    <div className="m-title login-title " style={{ margin: "1vh" }}>
                      <IonLabel className="wlcm-title">Welcome Back.</IonLabel>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <form onSubmit={handleSubmit}>
                <div className="the-form">
                  <div className="input-holder">
                    <IonLabel className="input-label">Email</IonLabel>
                    <IonItem className="login-ion-item input-item" lines="none">
                      <input
                        type="email"
                        name="email"
                        style={{ background: "rgba(255, 255, 255, 0)" }}
                        className='fontName login-input'
                        placeholder="Enter email"
                        value={inputEmail}
                        onChange={e => handleEmailInputChanges(e)}
                        onKeyDown={(event) => {
                          preventSpaceInput(event);
                          handleKeyPress(event);
                        }}
                      />
                      {/* <IonInput
                    className="form-input"
                    placeholder="Enter Name"
                    type="text"
                  /> */}
                      <IonIcon
                        slot="start"
                        style={{ fontSize: "17px", margin: "0px 10px 0px 0px" }}
                        className="fav"
                        src="assets/imgs/icons/email.svg"
                      />
                    </IonItem>
                  </div>
                  {formState.emailError && <span style={{ marginTop: "-2%" }} className='fontName emailErrorMsg'>{formState.emailError}</span>}
                  <div className="input-holder">
                    <IonLabel className="input-label">Password</IonLabel>
                    <IonItem className="login-ion-item input-item" lines="none">
                      <input
                        type='text'
                        name="password"
                        style={{ background: "rgba(255, 255, 255, 0)" }}
                        className={showPassword ? 'fontName login-input passwordVisible' : 'fontName login-input passwordHidden'}
                        placeholder="Enter password"
                        value={inputPassword}
                        onChange={handlePasswordInputChange}
                        onFocus={(e) => e.preventDefault()}
                      />
                      {/* <IonInput
                    className="form-input"
                    placeholder="Enter Password"
                    type="password"
                  /> */}
                      <IonIcon
                        slot="start"
                        style={{ fontSize: "20px", margin: "0px 8px 0px 0px" }}
                        className="fav"
                        src="assets/imgs/icons/password.svg"
                      />
                      <IonIcon
                        slot='end'
                        className='eye-icon'
                        onClick={handleTogglePassword}
                        icon={showPassword ? eyeOffOutline : eyeOutline}
                      />
                    </IonItem>
                  </div>
                </div>
                <div className="rem-holder">
                  <IonRow>
                    <IonCol size="12" col-12>
                      <div className="remind-item-div">
                        {/* <IonItem className="rem-item remind-item" lines="none">
                        <IonCheckbox onIonChange={checkboxChanged} className="rem-check" slot="start" mode="ios" />
                        <IonLabel className="rem-label">Remember me</IonLabel>
                      </IonItem> */}
                      
                        {/* <a href='https://partners.anythingforhire.com/password/reset'>
                          <IonLabel className="forgot-password">Forgot Password?</IonLabel>
                        </a> */}
                        <Link to="/password/reset">
                          <IonLabel class="forgot-password">
                            Forgot Password?
                          </IonLabel>
                        </Link>

                      </div>
                    </IonCol>
                  </IonRow>
                </div>

                <div className="btn-holder">
                  <IonButton expand="block" className="login-button" type="submit">
                    Login
                  </IonButton>
                  <IonLabel className="create-account">
                  Don't have an account?{" "}
                  <IonRouterLink style={{ textDecoration: "none" }} href="/sign-up">
                    <span className="create-account-span">SIGN UP</span>
                  </IonRouterLink>
                </IonLabel>
                </div>
              </form>
            </div>
          </div>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={errorMessage}
            duration={3000}
            color="danger"
          />
          <IonLoading
            isOpen={loading}
            spinner="circular"
            translucent={true}
          />
          <IonAlert
            isOpen={showBackAlert}
            onDidDismiss={() => setShowBackAlert(false)}
            header={'Exit App'}
            message={'Are you sure you want to exit the app?'}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  setShowBackAlert(false);
                }
              },
              {
                text: 'Exit',
                handler: () => {
                  CapacitorApp.exitApp();
                }
              }
            ]}
          />
          <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
            <IonContent className="model-bg">
              <IonGrid className='cnfmModelGrid'>
                <IonRow>
                  <IonCol size="12" col-12>
                    <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                  </IonCol>
                  <IonCol size="12" col-12>
                    <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                  </IonCol>
                  <IonCol size='12' col-12>
                    <IonButton
                      routerLink="/login"
                      routerDirection="none"
                      style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                      Back to Login
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
        </IonContent>
      }
    </IonPage >
  );
};

export default Login;
