// src/pages/NotFoundPage.tsx

import { IonButton, IonButtons, IonCard, IonContent, IonHeader, IonIcon, IonImg, IonLabel, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';
import { useHistory } from "react-router";

const NotFoundPage: React.FC = () => {
    const history = useHistory();
    const goBack = () => {
        history.goBack();
    };

    return (
        <>
              <IonHeader>
                    <IonToolbar className="lnd-header">
                        <div className="lnd-flx">
                        <IonImg className="lnd-header-img" src="assets/imgs/images/afh-logo-landscape-white.svg"></IonImg>
                        <div className="lnd-btns-div">
                        <IonButton 
                        className="lnd-header-btns"
                        routerLink="/sign-up"
                        routerDirection="none"
                        style={{ marginRight: "5%" }}
                        >Join us</IonButton>
                        <IonButton 
                        className="lnd-header-btns"
                        routerLink="/login"
                        routerDirection="none">Login</IonButton>
                        </div>
                        </div>
                    </IonToolbar>
                </IonHeader>
            <IonContent>
                <div className="holder ion-text-center">
                    <IonCard className='card-404'>
                        <h4 className='h4-404'>404 - page not found</h4>
                        <p className='p-404'>We're sorry but the page you're looking for doesn't exist. Here are some suggestions to help you find the right pages.</p>
                        <IonButton
                            routerLink="/login"
                            routerDirection="none" className='fontName bc-to-login booking-details-btn' expand="block">
                            Back to Login
                        </IonButton>
                    </IonCard>
                </div>
            </IonContent>
        </>
    );
};

export default NotFoundPage;
