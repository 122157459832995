import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PushNotifications } from '@capacitor/push-notifications';

import { Network } from '@capacitor/network';

import "./Home.css";
import { apiConfig, axiosInstance } from "../../apiConfig";

const Home: React.FC = () => {
  const darkModeValue = localStorage.getItem("darkMode");
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [dashboard, setDashboard] = useState<any>();
  
  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const history = useHistory();

  const [networkStatus, setNetworkStatus] = useState<any>(true);

  // CapacitorApp.addListener('backButton', () => {
  //   Dialog.confirm({
  //     title: 'Exit App',
  //     message: 'Are you sure you want to exit the app?',
  //     okButtonTitle: 'Exit',
  //     cancelButtonTitle: 'Cancel'
  //   }).then(response => {
  //     if (response.value == true) {
  //       CapacitorApp.exitApp();
  //     }
  //   });
  // });
  // CapacitorApp.addListener('backButton', ({ canGoBack }) => {
  //   if (!canGoBack) {
  //     CapacitorApp.exitApp();
  //   } else {
  //     window.history.back();
  //   }
  // });

  useIonViewWillLeave(() => {
    setDashboard(null);
  })

  useEffect(() => {
    
    if(localStorage.getItem("fromSignup") == "yes"){
      setWelcomeModal(true);
      localStorage.setItem("fromSignup", "no");
    } else {
      setWelcomeModal(false);
    }
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      localStorage.setItem("isLoggedIn","no");
      history.push('/login');
    }
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
      // if (status.connected == true) {
      //   dashboardFunction();
      // }
    });
    // logCurrentNetworkStatus();
  }, []);
  // const logCurrentNetworkStatus = async () => {
  //   const status = await Network.getStatus();
  //   setNetworkStatus(status);
  //   console.log('Network status:', status);
  // };


  useIonViewWillEnter(() => {
    localStorage.setItem("expensesInfo", "info");
    localStorage.setItem("servicesViewed","active");
    // console.log("isHomePage", isHomePage);
    // if (localStorage.getItem("locationPage") == "/tabs/dashboard") {
    //   CapacitorApp.addListener('backButton', () => {
    //     Dialog.confirm({
    //       title: 'Exit App',
    //       message: 'Are you sure you want to exit the app?',
    //       okButtonTitle: 'Exit',
    //       cancelButtonTitle: 'Cancel'
    //     }).then(response => {
    //       if (response.value == true) {
    //         CapacitorApp.exitApp();
    //       }
    //     });
    //   });
    // }
    dashboardFunction();
    checkPushNotificationPermissions();
  }, []);
  const checkPushNotificationPermissions = async () => {
    try {
      const { receive } = await PushNotifications.requestPermissions();

      if (receive === 'granted') {
        console.log('Push notifications are allowed');
      } else {
        console.log('Push notifications are not allowed');
      }
    } catch (error) {
      console.error('Error checking push notification permissions:', error);
    }
  };
  const dashboardFunction = () => {
    setLoading(true);
    setBackdropVisible(true);
    // Set a timeout to handle cases when there's no response for 5 seconds
    setTimeout(() => {
      console.log("loading", loading);
      if (loading == true) {
        setLoading(false);
        setBackdropVisible(false);
      }
    }, 5000);

    const endpoint = "/dashboard"
    axiosInstance.get(`${endpoint}`, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if (response.data.suspendstatus == false) {
          localStorage.setItem("isLoggedIn","no");
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          setDashboard(response.data.data);
          setLoading(false);
          setBackdropVisible(false);
        }
      })
      .catch(error => {
        setLoading(false);
        setBackdropVisible(false);
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
      });

  };  
  const handleDashboardRefresh = async (event: CustomEvent) => {
    setTimeout(() => {
      dashboardFunction();
      event.detail.complete();
    }, 1000);
  };
  const expensesInfo = () => {
    localStorage.setItem("expensesInfo", "info");
  }
  return (
    <IonPage>
      {darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border center-div">
            <IonToolbar className="home-toolbar header-toolbar">
              <IonImg slot="start" className="header-logo" src="assets/imgs/images/AFH-Logo-White.png"></IonImg>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn icon-btn-bell"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons bell-icon-m"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-home">
            <IonRefresher slot="fixed" onIonRefresh={handleDashboardRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            {networkStatus == false &&
              <div className="no-network-div">
                <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
                <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
              </div>
            }
            {/* <div className="background-holder"></div> */}
            {networkStatus == true &&
              dashboard &&
              <>
                <div className="center-div dash-width">
                  <div style={{ position: "relative" }} className="background-holder">
                    {/* style={{ top: "110px" }} */}
                    <div className="first-top-card">
                      <IonGrid>
                        <IonRow>
                          <IonCol size-md="10" size="4">
                            <div className="unread-div">
                              <IonLabel className="dash-main-title">
                                Dashboard
                              </IonLabel>
                            </div>
                          </IonCol>
                          <IonCol size-md="2" size="8">
                            <Link className="link-decoration" to={"/notifications"}>
                              <div className="unread-div notification-card">
                                <IonLabel className="unread-msg">
                                  Unread Messages
                                </IonLabel>
                                <IonBadge className="unread-badge">{dashboard.unreadnotificationcounts}</IonBadge>
                                <IonImg className="unread-img" src="assets/imgs/icons/rightArrow.svg" alt="rightArrow"></IonImg>
                              </div>
                            </Link>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                    <div className="dark-top-card animate__animated animate__bounceIn ion-padding-horizontal">
                      <IonGrid>
                        <IonRow>
                          <IonCol size-md='1' size="2">
                            <IonImg src="assets/imgs/icons/overallEarning.svg" className="all-img" alt="overallEarning"></IonImg>
                          </IonCol>
                          <IonCol size="10">
                            <div>
                              <IonLabel className="dark-overall-title">
                                Over All Earnings
                              </IonLabel>
                              <IonLabel className="dark-overall-pounds">
                                £{dashboard.alltimesales.toFixed(2)}
                              </IonLabel>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                  </div>
                  <div className="third-dash-card margin-top animate__animated animate__fadeInUp animate__slow">
                    <div className="dark-booking-sts-card">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="12">
                            <IonLabel className="dark-booking-sts-title">Booking Status:</IonLabel>
                          </IonCol>
                          <IonCol size-md='1' size="2" className="grid-p">
                            <IonImg src="assets/imgs/icons/allBookings.svg" className="all-img" alt="allBookings"></IonImg>
                          </IonCol>
                          <IonCol size-md='2' size="4" className="grid-p dark-border-right">
                            <div>
                              <IonLabel className="dark-overall-title">All Bookings</IonLabel>
                              <IonLabel className="dark-overall-pounds">{dashboard.totalbookingcount}</IonLabel>
                            </div>
                          </IonCol>
                          <IonCol size-md='1' size="2" className="grid-p">
                            <IonImg src="assets/imgs/icons/confirmed.svg" className="all-img" alt="confirmed"></IonImg>
                          </IonCol>
                          <IonCol size-md='2' size="4" className="grid-p">
                            <div>
                              <IonLabel className="dark-overall-title">Confirmed</IonLabel>
                              <IonLabel className="dark-overall-pounds">{dashboard.confirmedbookingscount}</IonLabel>
                            </div>
                          </IonCol>
                          <IonCol size-md='1' size="2" className="grid-p dark-border-top">
                            <IonImg src="assets/imgs/icons/unconfirmed.svg" className="all-img" alt="unconfirmed"></IonImg>
                          </IonCol>
                          <IonCol size-md='2' size="4" className="grid-p dark-border-right dark-border-top">
                            <div>
                              <IonLabel className="dark-overall-title">Unconfirmed</IonLabel>
                              <IonLabel className="dark-overall-pounds">{dashboard.unconfirmedandmodifiedbookingscount}</IonLabel>
                            </div>
                          </IonCol>
                          <IonCol size-md='1' size="2" className="grid-p dark-border-top">
                            <IonImg src="assets/imgs/icons/completed.svg" className="all-img" alt="completed"></IonImg>
                          </IonCol>
                          <IonCol size-md='2' size="4" className="grid-p dark-border-top">
                            <div>
                              <IonLabel className="dark-overall-title">Completed</IonLabel>
                              <IonLabel className="dark-overall-pounds">{dashboard.completedcount}</IonLabel>
                            </div>
                          </IonCol>
                        </IonRow>
                        {dashboard.unconfirmedandmodifiedbookingscount != 0 && (
                          <Link className="link-decoration" onClick={expensesInfo} to={'/tabs/bookings'}>
                            <IonRow className="info-grid">
                              {/* <IonCol col-2 size="2" style={{
                              display: "flex",
                              alignItems: "center"
                            }}>
                              <IonImg className="info-img" src="assets/imgs/icons/info.svg" alt="info"></IonImg>
                            </IonCol> */}
                              <IonCol col-11 size="11">
                                <div>
                                  <IonLabel className="dark-info-title">Info:</IonLabel>
                                  <IonLabel className="dark-info-msg">There are {dashboard.unconfirmedandmodifiedbookingscount} bookings that needs confirmation. please go ahead and confirm asap.</IonLabel>
                                </div>
                              </IonCol>
                              <IonCol col-1 size="1" style={{
                                display: "flex",
                                alignItems: "center"
                              }}>
                                <IonImg className="right-arrow" src="assets/imgs/icons/darkRightArrow.svg" alt="rightArrow"></IonImg>
                              </IonCol>
                            </IonRow>
                          </Link>
                        )}
                      </IonGrid>
                    </div>
                    {dashboard.rejectcount != 0 && (
                      <IonGrid className="dark-reject-booking-grid">
                        <IonRow>
                          <IonCol col-12 size="12" style={{
                            display: "flex",
                            alignItems: "center"
                          }}>
                            <IonImg src="assets/imgs/icons/rejectBooking.svg" className="all-img" alt="rejectBooking"></IonImg>

                            <div>
                              <IonLabel className="reject-title">Rejected Bookings</IonLabel>
                            </div>

                            <IonBadge className="right-reject-count unread-badge">{dashboard.rejectcount}</IonBadge>
                          </IonCol>
                          <IonCol col-12 size="12">
                            <IonLabel className="reject-msg">Please try to keep rejections as low as possible in order to get more sales from AFH</IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    )}
                    <div className="m-title table-m-title">
                      <IonLabel className="dark-dash-main-title table-main-title">Performance Report:</IonLabel>
                      <table className="dark-table">
                        <thead>
                          <tr>
                            <td className="dash-td dash-td-title split-up-heading" >Split up</td>
                            <td className="dash-td dash-td-title revenue-heading">Revenue</td>
                            <td className="dash-td dash-td-title bkng-count">Booking Count</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="dash-td">Today</td>
                            <td className="dash-td">£{dashboard.todaysales.toFixed(2)}</td>
                            <td style={{ textAlign: "center" }} className="dash-td">{dashboard.todaybookingscount}</td>
                          </tr>
                          <tr>
                            <td className="dash-td">This Week</td>
                            <td className="dash-td">£{dashboard.currentweeksales.toFixed(2)}</td>
                            <td style={{ textAlign: "center" }} className="dash-td">{dashboard.thisweekbookingscount}</td>
                          </tr>
                          <tr>
                            <td className="dash-td">This Month</td>
                            <td className="dash-td">£{dashboard.currentmonthsales.toFixed(2)}</td>
                            <td style={{ textAlign: "center" }} className="dash-td">{dashboard.thismonthbookingscount}</td>
                          </tr>
                          <tr>
                            <td className="dash-td">This Year</td>
                            <td className="dash-td">£{dashboard.currentyearsales.toFixed(2)}</td>
                            <td style={{ textAlign: "center" }} className="dash-td">{dashboard.thisyearbookingscount}</td>
                          </tr>
                          <tr>
                            <td className="dash-td" style={{ borderRadius: "0px 0px 0px 12px" }}>All Time</td>
                            <td className="dash-td" style={{ borderRadius: "0px 0px 0px 0px" }}>£{dashboard.alltimesales.toFixed(2)}</td>
                            <td style={{ textAlign: "center", borderRadius: "0px 0px 12px 0px" }} className="dash-td">{dashboard.totalbookingcount}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {dashboard.rejectcount != 0 && (
                      <IonGrid className="missing-image-grid" onClick={() => window.open('mailto:support@anythingforhire.com')}>
                        <IonRow>
                          <IonCol col-12 size="12" style={{
                            display: "flex",
                            alignItems: "center"
                          }}>
                            <IonImg src="assets/imgs/icons/missingImg.svg" className="all-img" alt="missingImg"></IonImg>

                            <div>
                              <IonLabel className="missing-title">Missing Images:</IonLabel>
                            </div>
                            <IonBadge className="unread-badge">{dashboard.missingimagecount}</IonBadge>
                            <IonImg className="right-arrow missing-img-right-arrow" src="assets/imgs/icons/rightArrow.svg" alt="rightArrow"></IonImg>
                          </IonCol>
                          <IonCol col-12 size="12">
                            <IonLabel className="missing-msg">{dashboard.missingimagecount} of your products are missing images, please get in touch with our sales team asap to get it uploaded, this will increase your chance to get more sales.</IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    )}
                    <div className="m-title">
                      <IonLabel className="dark-dash-main-title monthly-main-title">Monthly Performance ({dashboard.currentmonthandyear})</IonLabel>
                    </div>
                    <IonRow>
                      <IonCol size-md="2" size="6">
                        <div
                          className="progressbar-holder"
                          style={{ width: 150, height: 150, marginLeft: "10%" }}
                        >
                          <CircularProgressbarWithChildren
                            value={dashboard.monthlypercentage}
                            text={`${dashboard.monthlypercentage}` + "%"}
                            circleRatio={1.00}
                            styles={buildStyles({
                              rotation: 1 / 2 + 1 / 8,
                              strokeLinecap: "round",
                              trailColor: "#D2EBED",
                              pathColor: "#16C1D0",
                              textColor: "#CCCCCC",
                              textSize: "14px",
                            })}
                          >
                          </CircularProgressbarWithChildren>
                        </div>

                        <div className="precentage-row ion-padding-horizontal">
                          <IonRow>
                            <IonCol size="12" col-12 className="ion-text-left">
                              <IonLabel className="dark-month-label">This Month</IonLabel>
                            </IonCol>
                          </IonRow>
                        </div>
                      </IonCol>
                      <IonCol size-md="10" size="6">
                        <div style={{
                          padding: "15px",
                          paddingBottom: "30px"
                        }}>
                          <IonLabel className="dark-total-label-m">Total: {dashboard.thismonthbookingscount}</IonLabel>
                          <IonLabel className="dark-total-label-m">Completed: {dashboard.monthlycompletedcount}</IonLabel>
                          <div className="total-div">
                            <div className="blue-square"></div>
                            <IonLabel className="dark-total-label">Confirmed: {dashboard.confirmedbookingscount}</IonLabel>
                          </div>
                          <div className="total-div">
                            <div className="yellow-square"></div>
                            <IonLabel className="dark-total-label">Unconfirmed: {dashboard.unconfirmedandmodifiedbookingscount}</IonLabel>
                          </div>
                          <IonLabel className="dark-revenue-label">Revenue:</IonLabel>
                          <div className="total-div dark-revenue-div">
                            <IonImg src="assets/imgs/icons/revenue.svg" alt="revenue"></IonImg>
                            <IonLabel className="dark-total-label-badge" style={{ color: "#fff" }}>£{dashboard.currentmonthsales.toFixed(2)}</IonLabel>
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                </div>
              </>}
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true} />
            <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="dark-model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className='fontName booking-details-btn'
                        onClick={() => setShowSuspendedModal(false)}
                        expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
      {darkModeValue == "lightMode" &&
        <>
          <IonHeader className="ion-no-border center-div">
            <IonToolbar className="home-toolbar header-toolbar">
              <IonImg slot="start" className="header-logo" src="assets/imgs/images/AFH-Logo-White.png"></IonImg>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn icon-btn-bell"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons bell-icon-m"
                    src="assets/imgs/icons/bellWhite.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="home">
            <IonRefresher slot="fixed" onIonRefresh={handleDashboardRefresh}>
              <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            {networkStatus == false &&
              <div className="no-network-div center-div">
                <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                <h6 className='fontName'>Check your internet connection and try again</h6>
              </div>
            }
            {networkStatus == true &&
              dashboard &&
              <>
                <div className="center-div dash-width">
                  <div style={{ position: "relative" }} className="background-holder">
                    <div className="first-top-card">
                      <IonGrid>
                        <IonRow>
                          <IonCol size-md="10" size="4">
                            <div className="unread-div">
                              <IonLabel className="dash-main-title">
                                Dashboard {networkStatus.connected}
                              </IonLabel>

                            </div>
                          </IonCol>
                          <IonCol size-md="2" size="8">
                            <Link className="link-decoration" to={"/notifications"}>
                              <div className="notification-card unread-div">
                                <IonLabel className="unread-msg">
                                  Unread Messages
                                </IonLabel>
                                <IonBadge className="unread-badge">{dashboard.unreadnotificationcounts}</IonBadge>
                                <IonImg className="unread-img" src="assets/imgs/icons/rightArrow.svg" alt="rightArrow"></IonImg>
                              </div>
                            </Link>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                    <div className="top-card animate__animated animate__bounceIn ion-padding-horizontal">
                      <IonGrid>
                        <IonRow>
                          <IonCol size-md="1" size="2">
                            <IonImg src="assets/imgs/icons/overallEarning.svg" className="all-img" alt="overallEarning"></IonImg>
                          </IonCol>
                          <IonCol size-md='11' size="10">
                            <div>
                              <IonLabel className="overall-title">
                                Over All Earnings
                              </IonLabel>
                              <IonLabel className="overall-pounds">
                                £{dashboard.alltimesales.toFixed(2)}
                              </IonLabel>
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                  </div>
                  <div className="third-dash-card margin-top animate__animated animate__fadeInUp animate__slow">
                    <div className="booking-sts-card">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="12">
                            <IonLabel className="booking-sts-title">Booking Status:</IonLabel>
                          </IonCol>
                          <IonCol size-md='1' size="2" className="grid-p">
                            <IonImg src="assets/imgs/icons/allBookings.svg" className="all-img" alt="allBookings"></IonImg>
                          </IonCol>
                          <IonCol size-md='2' size="4" className="grid-p border-right">
                            <div>
                              <IonLabel className="overall-title">All Bookings</IonLabel>
                              <IonLabel className="overall-pounds">{dashboard.totalbookingcount}</IonLabel>
                            </div>
                          </IonCol>
                          <IonCol size-md='1' size="2" className="grid-p">
                            <IonImg src="assets/imgs/icons/confirmed.svg" className="all-img" alt="confirmed"></IonImg>
                          </IonCol>
                          <IonCol size-md='2' size="4" className="grid-p">
                            <div>
                              <IonLabel className="overall-title">Confirmed</IonLabel>
                              <IonLabel className="overall-pounds">{dashboard.confirmedbookingscount}</IonLabel>
                            </div>
                          </IonCol>
                          <IonCol size-md='1' size="2" className="grid-p border-top">
                            <IonImg src="assets/imgs/icons/unconfirmed.svg" className="all-img" alt="unconfirmed"></IonImg>
                          </IonCol>
                          <IonCol size-md='2' size="4" className="grid-p border-right border-top">
                            <div>
                              <IonLabel className="overall-title">Unconfirmed</IonLabel>
                              <IonLabel className="overall-pounds">{dashboard.unconfirmedandmodifiedbookingscount}</IonLabel>
                            </div>
                          </IonCol>
                          <IonCol size-md='1' size="2" className="grid-p border-top">
                            <IonImg src="assets/imgs/icons/completed.svg" className="all-img" alt="completed"></IonImg>
                          </IonCol>
                          <IonCol size-md='2' size="4" className="grid-p border-top">
                            <div>
                              <IonLabel className="overall-title">Completed</IonLabel>
                              <IonLabel className="overall-pounds">{dashboard.completedcount}</IonLabel>
                            </div>
                          </IonCol>
                        </IonRow>
                        {dashboard.unconfirmedandmodifiedbookingscount != 0 && (
                          <Link className="link-decoration" onClick={expensesInfo} to={'/tabs/bookings'}>
                            <IonRow className="info-grid">
                              {/* <IonCol col-2 size="2" style={{
                              display: "flex",
                              alignItems: "center"
                            }}>
                              <IonImg className="info-img" src="assets/imgs/icons/info.svg" alt="info"></IonImg>
                            </IonCol> */}
                              <IonCol size="11">
                                <div>
                                  <IonLabel className="info-title">Info:</IonLabel>
                                  <IonLabel className="info-msg">There are {dashboard.unconfirmedandmodifiedbookingscount} bookings that needs confirmation. please go ahead and confirm asap.</IonLabel>
                                </div>
                              </IonCol>
                              <IonCol size="1" style={{
                                display: "flex",
                                alignItems: "center"
                              }}>
                                <IonImg className="right-arrow" src="assets/imgs/icons/rightArrowBlk.svg" alt="rightArrow"></IonImg>
                              </IonCol>
                            </IonRow>
                          </Link>
                        )}
                      </IonGrid>
                    </div>
                    {dashboard.rejectcount != 0 && (
                      <IonGrid className="reject-booking-grid">
                        <IonRow>
                          <IonCol size="12" style={{
                            display: "flex",
                            alignItems: "center"
                          }}>
                            <IonImg src="assets/imgs/icons/rejectBooking.svg" className="all-img" alt="rejectBooking"></IonImg>

                            <div>
                              <IonLabel className="reject-title">Rejected Bookings</IonLabel>
                            </div>
                            <IonBadge className="right-reject-count unread-badge">{dashboard.rejectcount}</IonBadge>
                          </IonCol>
                          <IonCol size="12">
                            <IonLabel className="reject-msg">Please try to keep rejections as low as possible in order to get more sales from AFH</IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    )}
                    <div className="m-title table-m-title">
                      <IonLabel className="main-title table-main-title">Performance Report:</IonLabel>
                      <table className="table-dash">
                        <thead>
                          <tr>
                            <td className="dash-td split-up-heading">Split up</td>
                            <td className="dash-td revenue-heading">Revenue</td>
                            <td className="dash-td bkng-count">Booking Count</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="dash-td">Today</td>
                            <td className="dash-td">£{dashboard.todaysales.toFixed(2)}</td>
                            <td style={{ textAlign: "center" }} className="dash-td">{dashboard.todaybookingscount}</td>
                          </tr>
                          <tr>
                            <td className="dash-td">This Week</td>
                            <td className="dash-td">£{dashboard.currentweeksales.toFixed(2)}</td>
                            <td style={{ textAlign: "center" }} className="dash-td">{dashboard.thisweekbookingscount}</td>
                          </tr>
                          <tr>
                            <td className="dash-td">This Month</td>
                            <td className="dash-td">£{dashboard.currentmonthsales.toFixed(2)}</td>
                            <td style={{ textAlign: "center" }} className="dash-td">{dashboard.thismonthbookingscount}</td>
                          </tr>
                          <tr>
                            <td className="dash-td">This Year</td>
                            <td className="dash-td">£{dashboard.currentyearsales.toFixed(2)}</td>
                            <td style={{ textAlign: "center" }} className="dash-td">{dashboard.thisyearbookingscount}</td>
                          </tr>
                          <tr>
                            <td className="dash-td">All Time</td>
                            <td className="dash-td">£{dashboard.alltimesales.toFixed(2)}</td>
                            <td style={{ textAlign: "center" }} className="dash-td">{dashboard.totalbookingcount}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {dashboard.rejectcount != 0 && (
                      <IonGrid className="missing-image-grid" onClick={() => window.open('mailto:support@anythingforhire.com')}>
                        <IonRow>
                          <IonCol col-12 size="12" style={{
                            display: "flex",
                            alignItems: "center"
                          }}>
                            <IonImg src="assets/imgs/icons/missingImg.svg" className="all-img" alt="missingImg"></IonImg>

                            <div>
                              <IonLabel className="missing-title">Missing Images</IonLabel>
                            </div>
                            <IonBadge className="unread-badge">{dashboard.missingimagecount}</IonBadge>
                            <IonImg className="right-arrow missing-img-right-arrow" src="assets/imgs/icons/rightArrow.svg" alt="rightArrow"></IonImg>
                          </IonCol>
                          <IonCol col-12 size="12">
                            <IonLabel className="missing-msg">{dashboard.missingimagecount} of your products are missing images, please get in touch with our sales team asap to get it uploaded, this will increase your chance to get more sales.</IonLabel>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    )}
                    <div className="m-title">
                      <IonLabel className="main-title monthly-main-title">Monthly Performance ({dashboard.currentmonthandyear})</IonLabel>
                    </div>
                    <IonRow>
                      <IonCol size-md="2" size="6">
                        <div
                          className="progressbar-holder"
                          style={{ width: 150, height: 150, marginLeft: "10%" }}
                        >
                          <CircularProgressbarWithChildren
                            value={dashboard.monthlypercentage}
                            text={`${dashboard.monthlypercentage}` + "%"}
                            circleRatio={1.00}
                            styles={buildStyles({
                              rotation: 1 / 2 + 1 / 8,
                              strokeLinecap: "round",
                              trailColor: "#D2EBED",
                              pathColor: "#16C1D0",
                              textColor: "#515151",
                              textSize: "14px",
                            })}
                          >
                          </CircularProgressbarWithChildren>
                        </div>

                        <div className="precentage-row ion-padding-horizontal">
                          <IonRow>
                            <IonCol size="12" col-12 className="ion-text-left">
                              <IonLabel className="month-label">This Month</IonLabel>
                            </IonCol>
                          </IonRow>
                        </div>
                      </IonCol>
                      <IonCol size-md="10" size="6">
                        <div style={{
                          padding: "15px",
                          paddingBottom: "30px"
                        }}>
                          <IonLabel className="total-label-m">Total: {dashboard.thismonthbookingscount}</IonLabel>
                          <IonLabel className="total-label-m">Completed: {dashboard.monthlycompletedcount}</IonLabel>
                          <div className="total-div">
                            <div className="blue-square"></div>
                            <IonLabel className="total-label">Confirmed: {dashboard.confirmedbookingscount}</IonLabel>
                          </div>
                          <div className="total-div">
                            <div className="yellow-square"></div>
                            <IonLabel className="total-label">Unconfirmed: {dashboard.unconfirmedandmodifiedbookingscount}</IonLabel>
                          </div>
                          <IonLabel className="revenue-label">Revenue:</IonLabel>
                          <div className="total-div revenue-div">
                            <IonImg src="assets/imgs/icons/revenue.svg" alt="revenue"></IonImg>
                            <IonLabel className="total-label" style={{ color: "#fff" }}>£{dashboard.currentmonthsales.toFixed(2)}</IonLabel>
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                    {/* <IonRow>
              <IonCol size="6" col-6>
                <div
                  className="progressbar-holder"
                  style={{ width: 150, height: 150, marginLeft: "10%" }}
                >
                  <CircularProgressbarWithChildren
                    value={dashboard.previousmonthpercentage}
                    text={`${dashboard.previousmonthpercentage}` + "%"}
                    circleRatio={1.00}
                    styles={buildStyles({
                      rotation: 1 / 2 + 1 / 8,
                      strokeLinecap: "round",
                      trailColor: "#D2EBED",
                      pathColor: "#16C1D0",
                      textColor: "#515151",
                      textSize: "14px",
                    })}
                  >
                  </CircularProgressbarWithChildren>
                </div>

                <div className="precentage-row ion-padding-horizontal">
                  <IonRow>
                    <IonCol size="12" col-12 className="ion-text-left">
                      <IonLabel className="month-label">Last Month</IonLabel>
                    </IonCol>
                  </IonRow>
                </div>
              </IonCol>
              <IonCol col-6 size="6">
                <div style={{ padding: "20px" }}>
                  <IonLabel className="total-label-m">Total:</IonLabel>
                  <div className="total-div">
                    <div className="blue-square"></div>
                    <IonLabel className="total-label">Confirmed: 350</IonLabel>
                  </div>
                  <div className="total-div">
                    <div className="yellow-square"></div>
                    <IonLabel className="total-label">Unconfirmed: 50</IonLabel>
                  </div>
                  <IonLabel className="revenue-label">Revenue:</IonLabel>
                  <div className="total-div revenue-div">
                    <IonImg src="assets/imgs/icons/revenue.svg" alt="revenue"></IonImg>
                    <IonLabel className="total-label">£{dashboard.previousmonthsales.toFixed(2)}</IonLabel>
                  </div>
                </div>
              </IonCol>
            </IonRow> */}
                  </div>
                </div>
              </>
            }
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true} />
            <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonModal isOpen={welcomeModal} id="welcome-modal" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow style={{display: "inline"}}>
                    {/* <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol> */}
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>Welcome to AFH Partner Centre</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>Hey {localStorage.getItem("companyName")}, welcome to the Anything for Hire Partner Centre. We have developed a fantastic set of features to help you control your bookings as well as your business with convenience.</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton className='welcome-close-btn fontName' onClick={() => { setWelcomeModal(false); }} expand="block">
                        Close
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
    </IonPage>
  );
};

export default Home;
function ionViewWillLeave(arg0: () => void) {
  throw new Error("Function not implemented.");
}

