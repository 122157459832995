import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonPopover,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToast,
  IonToolbar,
  SearchbarChangeEventDetail,
  useIonViewWillEnter,
} from "@ionic/react";

import { useEffect, useState } from "react";
import "./Expenses.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { apiConfig, axiosInstance } from "../../apiConfig";
import axios from "axios";
import { Geolocation } from '@ionic-native/geolocation';
import { Network } from '@capacitor/network';

const Expenses: React.FC = () => {

  const darkModeValue = localStorage.getItem("darkMode");

  const history = useHistory();

  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);

  const [segment, setSegment] = useState('unconfirmed');

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [unconfirmedSearchQuery, setUnconfirmedSearchQuery] = useState<string>('');
  const [expiredSearchQuery, setExpiredSearchQuery] = useState<string>('');

  const [isBookingsLoading, setIsBookingsLoading] = useState(false);


  const [bookingEmptyMsg, setBookingEmptyMsg] = useState(false);
  const [unconfirmedBookingEmptyMsg, setUnconfirmedBookingEmptyMsg] = useState(false);
  const [expiredBookingEmptyMsg, setExpiredBookingEmptyMsg] = useState(false);


  const [booking, setbooking] = useState<Array<any>>([]);
  const [unconfirmedBooking, setUnconfirmedBooking] = useState<Array<any>>([]);
  const [expiredBooking, setExpiredBooking] = useState<Array<any>>([]);


  const [loading, setLoading] = useState(true);
  const [backdropVisible, setBackdropVisible] = useState(false);

  const [completedCount, setCompletedCount] = useState(0);

  const [confirmedSortCount, setConfirmedSortCount] = useState(0);
  const [unConfirmedSortCount, setUnConfirmedSortCount] = useState(0);

  const [showToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [sortOption, setSortOption] = useState('datetime');
  const [confirmSortOption, setConfirmSortOption] = useState('datetime');
  const [showPopover, setShowPopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });
  const [showConfirmPopover, setShowConfirmPopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined });

  const currentDate = new Date();

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');

  const goBack = () => {
    history.goBack();
  };

  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);


  const [networkStatus, setNetworkStatus] = useState<any>(true);
  useEffect(() => {
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
    });
  }, []);


  useIonViewWillEnter(() => {
    localStorage.setItem("servicesViewed","active");
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      history.push('/login');
    }
    getLocation();
    BookingCountFunction();
    if (localStorage.getItem("expensesInfo") == "info") {
      setSegment('unconfirmed');
      setSortOption('datetime');
      unConfirmFunction();
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    } else if (localStorage.getItem("confirmed") == "confirmed" && (localStorage.getItem("confirmed") == null || localStorage.getItem("confirmed") == undefined || localStorage.getItem("confirmed") == "")) {
      setSegment('confirmed');
      setConfirmSortOption('datetime');
      confirmFunction();
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    } else if (localStorage.getItem("expired") == "expired") {
      setSegment('expired');
      expiredFunction();
      setSortOption('datetime');
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    } else {
      if (segment == "confirmed") {
        setConfirmSortOption('datetime');
        confirmFunction();
        setSearchQuery('');
        setUnconfirmedSearchQuery('');
        setExpiredSearchQuery('');
      } else if (segment == "unconfirmed") {
        setSortOption('datetime');
        unConfirmFunction();
        setSearchQuery('');
        setUnconfirmedSearchQuery('');
        setExpiredSearchQuery('');
      } else {
        expiredFunction();
        setSortOption('datetime');
        setSearchQuery('');
        setUnconfirmedSearchQuery('');
        setExpiredSearchQuery('');
      }
    }
  }, [])
  const BookingCountFunction = () => {
    const endpoint = "/bookingcounts"
     axiosInstance.get(`${apiConfig.baseUrl}${endpoint}`, { headers: apiConfig.getHeaders(endpoint) })
      .then(res => {
        if(res.data.message == "User not found") {
          setLoading(false);
          setBackdropVisible(false);
          history.push('/login');
        } else {
        setConfirmedSortCount(res.data.confirmedbookingscount);
        setUnConfirmedSortCount(res.data.unconfirmedbookingscount);
        setCompletedCount(res.data.completedcount);
        }
      })
      .catch((e) => {
        console.log('Errorrrrr-->>', e);
        if (e.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }

  const confirmFunction = () => {
    setConfirmSortOption('datetime');
    localStorage.setItem("confirmed", '');
    setBookingEmptyMsg(false);
    setbooking([]);
    setLoading(true);
    BookingCountFunction();
    setBackdropVisible(true);
    setTimeout(() => {
      console.log("loading", loading);
      if (loading == true) {
        setLoading(false);
        setBackdropVisible(false);
      }
    }, 5000);
    const endpoint = "/bookingslist"
     axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {
      "status": [2]
    }, { headers: apiConfig.getHeaders(endpoint) })
      .then(res => {
        if (res.data.suspendstatus == false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          setConfirmedSortCount(res.data.data.length);
          if (res.data.data.length === 0) {
            setBookingEmptyMsg(true);
            setbooking(res.data.data);
            setLoading(false);
            setBackdropVisible(false);
            setIsBookingsLoading(true);
          } else {
            setBookingEmptyMsg(false);
            setbooking(res.data.data);
            setLoading(false);
            setBackdropVisible(false);
            setIsBookingsLoading(false);
          }
        }
      })
      .catch((e: any) => {

        if (e.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }
  const getBookingId = (e: any) => {
    console.log("e----->>", e);
    localStorage.setItem('bookingId', e);
    // history.push("/viewbooking");
  }

  const getPrintBookingId = (e: any) => {
    console.log("e----->>", e);
    localStorage.setItem('printBookingId', e);
    // history.push("/viewbooking");
  }

  const getLocation = async () => {
    try {
      const position = await Geolocation.getCurrentPosition();
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
      console.log("Current Position:", position.coords.latitude, position.coords.longitude);
      return {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
    } catch (error) {
      console.error('Error getting current location:', error);
      return null;
    }
  };
  
  const handleGoogleMapLink = async (postCode: any) => {
    if (!postCode) {
      console.error('Invalid post code.');
      return;
    }
  
    if (latitude && longitude) {
      window.open(`https://www.google.com/maps/dir/${latitude},${longitude}/${postCode}`);
    } else {
      const location = await getLocation();
      if (location) {
        window.open(`https://www.google.com/maps/dir/${location.latitude},${location.longitude}/${postCode}`);
      } else {
        console.error('Unable to fetch location.');
        setShowErrorToast(true);
        setErrorMessage("Could not fetch location. Please enable location services.")
        alert('Could not fetch location. Please enable location services.');
      }
    }
  };

  const unConfirmFunction = () => {
    setSortOption('datetime');
    localStorage.setItem("expensesInfo", "");
    setUnconfirmedBookingEmptyMsg(false);
    setUnconfirmedBooking([]);
    BookingCountFunction();
    setLoading(true);
    setBackdropVisible(true);
    setTimeout(() => {
      console.log("loading", loading);
      if (loading == true) {
        setLoading(false);
        setBackdropVisible(false);
      }
    }, 5000);
    const endpoint = "/bookingslist"
     axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {
      "status": [3, 4]
    }, { headers: apiConfig.getHeaders(endpoint) })
      .then(res => {
        if (res.data.suspendstatus == false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          setUnConfirmedSortCount(res.data.data.length);
          if (res.data.data.length === 0) {
            setUnconfirmedBookingEmptyMsg(true);
            setUnconfirmedBooking([]);
            setLoading(false);
            setBackdropVisible(false);
            setIsBookingsLoading(true);
          } else {
            setUnconfirmedBookingEmptyMsg(false);
            setUnconfirmedBooking(res.data.data);
            setLoading(false);
            setBackdropVisible(false);
            setIsBookingsLoading(false);
          }
        }
      })
      .catch((e) => {

        if (e.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }
  const expiredFunction = () => {
    localStorage.setItem("expired", '');
    setExpiredBookingEmptyMsg(false);
    setExpiredBooking([]);
    BookingCountFunction();
    setLoading(true);
    setBackdropVisible(true);
    const timeoutId = setTimeout(() => {
      console.log("loading", loading);
      if (loading == true) {
        setLoading(false);
        setBackdropVisible(false);
      }
    }, 1000);
    const endpoint = "/bookingslist"
     axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {}, { headers: apiConfig.getHeaders(endpoint) })
      .then(res => {
        clearTimeout(timeoutId);
        if (res.data.suspendstatus == false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          setCompletedCount(res.data.data.length);
          localStorage.setItem("expensesInfo", "");
          if (res.data.data.length === 0) {
            setExpiredBookingEmptyMsg(true);
            setExpiredBooking(res.data.data);
            setLoading(false);
            setBackdropVisible(false);
            setIsBookingsLoading(true);
          } else {
            setExpiredBookingEmptyMsg(false);
            setExpiredBooking(res.data.data);
            setLoading(false);
            setBackdropVisible(false);
            setIsBookingsLoading(false);
          }
        }
      })
      .catch((e: any) => {
        clearTimeout(timeoutId);

        if (e.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      })
  }

  const handleSearch = (e: CustomEvent<SearchbarChangeEventDetail>) => {
    const value = e.detail.value;
    setSearchQuery(value ?? '');
  };
  const handleUnconfirmedSearch = (e: CustomEvent<SearchbarChangeEventDetail>) => {
    const value = e.detail.value;
    setUnconfirmedSearchQuery(value ?? '');
  };
  const handleExpiredSearch = (e: CustomEvent<SearchbarChangeEventDetail>) => {
    const value = e.detail.value;
    setExpiredSearchQuery(value ?? '');
  };
  const handleInputChange = (event: any) => {
    setIsBookingsLoading(true);
    if (event.detail.value == "confirmed") {
      setSortOption('datetime');
      confirmFunction();
      setSegment("confirmed");
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    } else if (event.detail.value == "unconfirmed") {
      setSortOption('datetime');
      setSegment("unconfirmed");
      unConfirmFunction();
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    } else {
      setSortOption('datetime');
      setSegment("expired");
      expiredFunction();
      setSearchQuery('');
      setUnconfirmedSearchQuery('');
      setExpiredSearchQuery('');
    }
  };
  function parseDate(input: string) {
    if (typeof input !== 'string') {
      throw new Error('Input must be a valid string.');
    }

    const parts = input.match(/(\d+)/g);
    if (!parts || parts.length < 5) {
      return new Date(NaN);
    }
    return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]), parseInt(parts[3]), parseInt(parts[4]));
  }
  const handleConfirmRefresh = async (event: CustomEvent) => {
    setBookingEmptyMsg(false);
    setbooking([]);
    BookingCountFunction();
    setTimeout(() => {
      console.log("loading", loading);
      if (loading == true) {
        event.detail.complete();

      }
    }, 5000);
    const endpoint = "/bookingslist"
     axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {
      "status": [2]
    }, { headers: apiConfig.getHeaders(endpoint) })
      .then(res => {
        if (res.data.suspendstatus == false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          event.detail.complete();

        } else {
          setConfirmedSortCount(res.data.data.length);
          localStorage.setItem("expensesInfo", "");
          if (res.data.data.length === 0) {
            setBookingEmptyMsg(true);
            setbooking(res.data.data);
            event.detail.complete();

            setIsBookingsLoading(true);
          } else {
            setBookingEmptyMsg(false);
            setbooking(res.data.data);
            event.detail.complete();

            setIsBookingsLoading(false);
          }
        }
      })
      .catch((e: any) => {

        if (e.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        event.detail.complete();

      })
  };
  const handleUnconfirmRefresh = async (event: CustomEvent) => {
    setUnconfirmedBookingEmptyMsg(false);
    setUnconfirmedBooking([]);
    BookingCountFunction();

    setTimeout(() => {
      console.log("loading", loading);
      if (loading == true) {
        setLoading(false);
        setBackdropVisible(false);
      }
    }, 5000);
    const endpoint = "/bookingslist"
     axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {
      "status": [3, 4]
    }, { headers: apiConfig.getHeaders(endpoint) })
      .then(res => {
        if (res.data.suspendstatus == false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          event.detail.complete();

        } else {
          setUnConfirmedSortCount(res.data.data.length);
          if (res.data.data.length === 0) {
            setUnconfirmedBookingEmptyMsg(true);
            setUnconfirmedBooking([]);
            event.detail.complete();

            setIsBookingsLoading(true);
          } else {
            setUnconfirmedBookingEmptyMsg(false);
            setUnconfirmedBooking(res.data.data);
            event.detail.complete();

            setIsBookingsLoading(false);
          }
        }
      })
      .catch((e) => {

        if (e.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        event.detail.complete();

      })
  };
  const handleExpiredRefresh = async (event: CustomEvent) => {
    setExpiredBookingEmptyMsg(false);
    setExpiredBooking([]);
    BookingCountFunction();
    const timeoutId = setTimeout(() => {
      console.log("loading", loading);
      if (loading == true) {
        event.detail.complete();

      }
    }, 1000);
    const endpoint = "/bookingslist"
     axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {}, { headers: apiConfig.getHeaders(endpoint) })
      .then(res => {
        clearTimeout(timeoutId);
        if (res.data.suspendstatus == false) {
          setSupendedMsg(res.data.message);
          setSupendedTitle(res.data.title);
          setShowSuspendedModal(true);
          event.detail.complete();

        } else {
          setCompletedCount(res.data.data.length);
          if (res.data.data.length === 0) {
            setExpiredBookingEmptyMsg(true);
            setExpiredBooking(res.data.data);
            event.detail.complete();

            setIsBookingsLoading(true);
          } else {
            setExpiredBookingEmptyMsg(false);
            setExpiredBooking(res.data.data);
            event.detail.complete();

            setIsBookingsLoading(false);
          }
        }
      })
      .catch((e: any) => {
        clearTimeout(timeoutId);

        if (e.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        event.detail.complete();

      })
  };
  const handleConfirmedFilter = (bookingList: any, filterOption: string, currentDate: Date) => {
    let filteredList = bookingList;

    if (filterOption === 'thisWeek') {
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      filteredList = filteredList.filter((booking: any) => {
        const bookingDate = parseDate(booking.lead_details.servicedatetime);
        return bookingDate >= startOfWeek && bookingDate <= endOfWeek;
      });
    } else if (filterOption === 'thisMonth') {
      filteredList = filteredList.filter((booking: any) => {
        const bookingDate = parseDate(booking.lead_details.servicedatetime);
        return (
          bookingDate.getMonth() === currentDate.getMonth() &&
          bookingDate.getFullYear() === currentDate.getFullYear()
        );
      });
    } else if (filterOption === 'thisYear') {
      filteredList = filteredList.filter((booking: any) => {
        const bookingDate = parseDate(booking.lead_details.servicedatetime);
        return bookingDate.getFullYear() === currentDate.getFullYear();
      });
    } else if (filterOption === 'status') {
      console.log('Filtering by status');
      filteredList = bookingList.filter((user: any) => user.status == "4");
    } else if (filterOption === 'postponed') {
      console.log('Filtering by postponed');
      filteredList = bookingList.filter((booking: any) => booking.postponed == "1");
    } else if (filterOption === 'confirmed') {
      console.log('Filtering by confirmed');
      filteredList = bookingList.filter((unconfirmed: any) => unconfirmed.status == "2");
    } else if (filterOption === 'returnTrip') {
      filteredList = bookingList.filter((booking: any) => booking.lead_details.isReturnTrip === "true");
  } else {
      filteredList = bookingList;
    }

    filteredList.sort((a: any, b: any) => {
      const dateA = parseDate(a.lead_details.servicedatetime);
      const dateB = parseDate(b.lead_details.servicedatetime);
      return dateA.getTime() - dateB.getTime();
    });
    setTimeout(() => {
      setConfirmedSortCount(filteredList.length);
    }, 1000);
    return filteredList;
  };

  const handleFilter = (bookingList: any, filterOption: string, currentDate: Date) => {
    let filteredList = bookingList;

    if (filterOption === 'thisWeek') {
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);
        filteredList = filteredList.filter((booking: any) => {
            const bookingDate = parseDate(booking.lead_details.servicedatetime);
            return bookingDate >= startOfWeek && bookingDate <= endOfWeek;
        });
    } else if (filterOption === 'thisMonth') {
        filteredList = filteredList.filter((booking: any) => {
            const bookingDate = parseDate(booking.lead_details.servicedatetime);
            return (
                bookingDate.getMonth() === currentDate.getMonth() &&
                bookingDate.getFullYear() === currentDate.getFullYear()
            );
        });
    } else if (filterOption === 'thisYear') {
        filteredList = filteredList.filter((booking: any) => {
            const bookingDate = parseDate(booking.lead_details.servicedatetime);
            return bookingDate.getFullYear() === currentDate.getFullYear();
        });
    } else if (filterOption === 'status') {
        filteredList = bookingList.filter((user: any) => user.status == "4");
    } else if (filterOption === 'postponed') {
        filteredList = bookingList.filter((booking: any) => booking.postponed == "1");
    } else if (filterOption === 'unconfirmed') {
        filteredList = bookingList.filter((unconfirmed: any) => unconfirmed.status == "3");
    } else if (filterOption === 'returnTrip') {
        filteredList = bookingList.filter((booking: any) => booking.lead_details.isReturnTrip === "true");
    } else {
        filteredList = bookingList;
    }

    filteredList.sort((a: any, b: any) => {
        const dateA = parseDate(a.lead_details.servicedatetime);
        const dateB = parseDate(b.lead_details.servicedatetime);
        return dateA.getTime() - dateB.getTime();
    });

    setTimeout(() => {
        console.log("count------->", filteredList.length);
        setUnConfirmedSortCount(filteredList.length);
    }, 1000);

    return filteredList;
};
  
  const handleExpiredSort = (bookingList: any) => {
    const sortedList = [...bookingList];
    sortedList.sort((a, b) => {
      if (sortOption === 'status') {
        const statusA = String(a.status);
        const statusB = String(b.status);
        return statusB.localeCompare(statusA);
      } else if (sortOption === 'datetime') {
        // Using the helper function to parse date strings
        const dateA = parseDate(a.lead_details.servicedatetime);
        const dateB = parseDate(b.lead_details.servicedatetime);
        return dateB.getTime() - dateA.getTime();
      } else {
        return a.id - b.id;
      }
    });
    return sortedList;
  };
  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="dark-ion-title">
                Bookings
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true &&
        darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle className="dark-ion-title">
                Bookings
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="dark-mode-ion-content">
            {segment === "confirmed" && (
              <IonRefresher slot="fixed" onIonRefresh={handleConfirmRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {segment === "unconfirmed" && (
              <IonRefresher slot="fixed" onIonRefresh={handleUnconfirmRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {segment === "expired" && (
              <IonRefresher slot="fixed" onIonRefresh={handleExpiredRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            <div className="search-holder">
              {segment === "confirmed" && (
                <IonGrid style={{ padding: "0 6px" }}>
                  <IonRow>
                    <IonCol size-md='11' size="10">
                      <IonSearchbar
                        style={{ height: "40px" }}
                        placeholder='Confirmed Bookings'
                        className="dark-search-bar"
                        mode="ios"
                        debounce={1000}
                        onIonChange={handleSearch}
                      ></IonSearchbar>
                    </IonCol>
                    <IonCol size-md='1' size="2" style={{ display: "flex" }}>
                      <IonButtons className="dark-sort-btn" onClick={(e) => setShowConfirmPopover({ open: true, event: e.nativeEvent })}>
                        <IonButton>
                          <IonIcon
                            style={{ fontSize: "22px" }}
                            className="fav"
                            src="assets/imgs/icons/darkSort.svg" />
                          <span className="sort-count-badge">{confirmedSortCount}</span>
                        </IonButton>
                      </IonButtons>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
              {segment === "unconfirmed" && (
                <IonGrid style={{ padding: "0 6px" }}>
                  <IonRow>
                    <IonCol size-md='11' size="10">
                      <IonSearchbar
                        style={{ height: "40px" }}
                        className="dark-search-bar"
                        placeholder='Unconfirmed Bookings'
                        mode="ios"
                        debounce={1000}
                        onIonChange={handleUnconfirmedSearch}
                      ></IonSearchbar>
                    </IonCol>
                    <IonCol size-md='1' size="2" style={{ display: "flex" }}>
                      <IonButtons className="dark-sort-btn" onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}>
                        <IonButton>
                          <IonIcon
                            style={{ fontSize: "22px" }}
                            className="fav"
                            src="assets/imgs/icons/darkSort.svg" />
                          <span className="sort-count-badge">{unConfirmedSortCount}</span>
                        </IonButton>
                      </IonButtons>
                    </IonCol>
                  </IonRow>
                </IonGrid>

              )}
              {segment === "expired" && (
                <IonGrid style={{ padding: "0 6px" }}>
                  <IonRow>
                    <IonCol size="12">
                      <IonSearchbar
                        style={{ height: "40px" }}
                        className="dark-search-bar"
                        placeholder='Expired Bookings'
                        mode="ios"
                        debounce={1000}
                        onIonChange={handleExpiredSearch}
                      ></IonSearchbar>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
            </div>
            <div className="details">
              <div className="segment-holder segment-width">
                <IonSegment className='fontName' value={segment} onIonChange={handleInputChange}>
                  <IonSegmentButton className='fontName dark-custom-segment-button-unconfirmed' value="unconfirmed">
                    <h6 className="light-mode-segements">Unconfirmed <span className='spanCount'>{unConfirmedSortCount}</span></h6>
                  </IonSegmentButton>
                  <IonSegmentButton className='fontName dark-custom-segment-button-confirmed' value="confirmed">
                    <h6 className="light-mode-segements">Confirmed <span className='spanCount'>{confirmedSortCount}</span></h6>
                  </IonSegmentButton>
                  <IonSegmentButton className='fontName dark-custom-segment-button-expired' value="expired">
                    <h6 className="light-mode-segements">Completed <span className='spanCount'>{completedCount}</span></h6>
                  </IonSegmentButton>
                </IonSegment>
              </div>
              {segment === "confirmed" && (
                <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                  {bookingEmptyMsg == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6 className="fontName dark-nocardh6">
                              Sorry, there is no Bookings for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {isBookingsLoading ? (
                        <div></div>
                      ) : (
                        (() => {
                          const filteredBookings = booking.filter(user => user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.lead_details.product.category.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.lead_details.product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.id.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.lead.mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.lead.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.lead_details.servicedate.includes(searchQuery) ||
                            user.lead_details.servicetime.includes(searchQuery)
                          );
                          const sortedFilteredBookings = handleConfirmedFilter(filteredBookings, confirmSortOption, currentDate);
                          return sortedFilteredBookings.length > 0 ? (
                            sortedFilteredBookings.map((user: any, i: any) => (
                              <IonCol key={i} size-lg="4" size-md="6" size="12">
                                <IonCard className="dark-overall-list">
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size-md='1' size='3'>
                                        <IonAvatar className="booking-img">
                                          <IonImg src={user.lead_details.product.image ? user.lead_details.product.image : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                          {user.postponed == "1" && (
                                            <h6 className="postponed-batch">Postponed</h6>
                                          )}
                                        </IonAvatar>
                                      </IonCol>
                                      <IonCol size-md='11' size="9" className="second-col">
                                        <div className="d-first-div">
                                          <div className="d-flex first-div">
                                            <IonLabel className="dark-first-title">{user.lead_details.product.category.title}</IonLabel>
                                            <IonLabel className="dark-booking-id-label">#{user.id}</IonLabel>
                                          </div>
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/darkPerson.svg" />
                                            <IonLabel className="label-align dark-middle-label label-ellipsis"> {user.lead.firstname} {user.lead.lastname}</IonLabel>
                                          </div>
                                          <br />
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/dark-car-booking.svg" />
                                            <IonLabel className="label-align dark-middle-label label-ellipsis"> {user.lead_details.product.title}</IonLabel>
                                          </div>
                                          <a style={{ textDecoration: "none" }} href={"tel:" + user.lead.mobile}>
                                            <IonImg className="call-icon" src="assets/imgs/icons/phone.svg"></IonImg>
                                          </a>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                    <hr className="dark-hr " />
                                    <IonRow style={{ display: "inline-block", width: "100%" }}>
                                      <IonCol size="7" col-7>
                                        <div className="div-align">
                                          <img className="img-align" src="assets/imgs/icons/darkCalendar.svg" />
                                          <IonLabel className="label-align dark-date-time-color label-font-size"> {user.lead_details.servicedate} |</IonLabel>
                                        </div>
                                        <div className="div-align">
                                          <img className="img-align" style={{ marginLeft: "5px" }} src="assets/imgs/icons/darkClock.svg" />
                                          <IonLabel className="label-align dark-date-time-color label-font-size"> {user.lead_details.servicetime}</IonLabel>
                                        </div>
                                      </IonCol>
                                      <IonCol className="postcode-col" size="5" col-5>
                                        <div className="div-align">
                                          {latitude == null && longitude == null ? (
                                            <button
                                              style={{ background: "#fff0" }}
                                              onClick={getLocation}
                                            >
                                              <img className="img-align" src="assets/imgs/icons/darkCompass.svg" />
                                              <IonLabel className="label-align postcode-label label-font-size">
                                                {user.lead_details.from_postcode}
                                              </IonLabel>
                                            </button>
                                          ) : (
                                            <button
                                              style={{ background: "#fff0" }}
                                              onClick={() => handleGoogleMapLink(user.lead_details.from_postcode)}
                                            >
                                              <img className="img-align" src="assets/imgs/icons/darkCompass.svg" />
                                              <IonLabel className="label-align postcode-label label-font-size">
                                                {user.lead_details.from_postcode}
                                              </IonLabel>
                                            </button>
                                          )}
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol size="12" size-md="10">
                                        <IonButton expand="block" onClick={() => getBookingId(user.id)} routerLink="/viewbooking" routerDirection="none" className="booking-details-btn" >
                                          View Booking Details
                                        </IonButton>
                                      </IonCol>
                                      <IonCol className="m-d-none" size="2">
                                        <IonButton className="print-btn" onClick={() => getPrintBookingId(user.id)} routerLink="/print/booking" routerDirection="none">
                                          <IonIcon
                                              className="fav print-icon"
                                              src="assets/imgs/icons/print.svg"
                                              slot="end"
                                              />
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonCard>
                              </IonCol>
                            ))
                          ) : (
                            <IonGrid>
                              <IonRow>
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 className="fontName dark-nocardh6"
                                    >
                                      Sorry, there is no Bookings for you
                                    </h6>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          );
                        })()

                      )}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {segment == "unconfirmed" && (
                <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                  <IonGrid>
                    <IonRow>
                      {isBookingsLoading ? (
                        <div></div>
                      ) : (
                        (() => {
                          const filteredBookings = unconfirmedBooking.filter(user => user.name.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.lead_details.product.category.title.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.lead_details.product.title.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.id.toString().toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.lead.mobile.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.lead.email.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.lead_details.servicedate.includes(unconfirmedSearchQuery) ||
                            user.lead_details.servicetime.includes(unconfirmedSearchQuery)
                          );

                          const sortedFilteredBookings = handleFilter(filteredBookings, sortOption, currentDate);
                          // const sortedFilteredBookings = handleSort(filteredStatusBookings, sortOption);
                          return sortedFilteredBookings.length > 0 ? (
                            sortedFilteredBookings.map((user: any, i: any) => (
                              <IonCol key={i} size-lg="4" size-md="6" size="12">
                                <IonCard className="dark-overall-list">
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size-md='1' size='3'>
                                        <div className="ribbon-wrapper">
                                          {user.status == "3" && (
                                            <div className="ribbon">Unconfirmed</div>
                                          )}
                                          {user.status == "4" && (
                                            <div className="ribbon-modified">Modified</div>
                                          )}
                                        </div>
                                        <IonAvatar className="booking-img">
                                          <IonImg src={user.lead_details.product.image ? user.lead_details.product.image : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                          {user.postponed == "1" && (
                                            <h6 className="postponed-batch">Postponed</h6>
                                          )}
                                        </IonAvatar>
                                      </IonCol>
                                      <IonCol size-md='11' size="9" className="second-col">
                                        <div className="d-first-div">
                                          <div className="d-flex first-div">
                                            <IonLabel className="dark-first-title">{user.lead_details.product.category.title}</IonLabel>
                                            <IonLabel className="dark-booking-id-label">#{user.id}</IonLabel>
                                          </div>
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/darkPerson.svg" />
                                            <IonLabel className="label-align label-ellipsis dark-middle-label"> {user.lead.firstname} {user.lead.lastname}</IonLabel>
                                          </div>
                                          <br />
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/dark-car-booking.svg" />
                                            <IonLabel className="label-align label-ellipsis dark-middle-label"> {user.lead_details.product.title}</IonLabel>
                                          </div>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                    <hr className="dark-hr " />
                                    <IonRow style={{ display: "inline-block", width: "100%" }}>
                                      <IonCol size="7" col-7>
                                        <div className="div-align">
                                          <img className="img-align" src="assets/imgs/icons/darkCalendar.svg" />
                                          <IonLabel className="label-align dark-date-time-color label-font-size"> {user.lead_details.servicedate} |</IonLabel>
                                        </div>
                                        <div className="div-align">
                                          <img className="img-align" style={{ marginLeft: "5px" }} src="assets/imgs/icons/darkClock.svg" />
                                          <IonLabel className="label-align dark-date-time-color label-font-size"> {user.lead_details.servicetime}</IonLabel>
                                        </div>
                                      </IonCol>
                                      <IonCol className="postcode-col" size="5" col-5>
                                        <div className="div-align">
                                          {latitude == null && longitude == null ? (
                                            <button
                                              style={{ background: "#fff0" }}
                                              onClick={getLocation}
                                            >
                                              <img className="img-align" src="assets/imgs/icons/darkCompass.svg" />
                                              <IonLabel className="label-align postcode-label label-font-size">
                                                {user.lead_details.from_postcode}
                                              </IonLabel>
                                            </button>
                                          ) : (
                                            <button
                                              style={{ background: "#fff0" }}
                                              onClick={() => handleGoogleMapLink(user.lead_details.from_postcode)}
                                            >
                                              <img className="img-align" src="assets/imgs/icons/darkCompass.svg" />
                                              <IonLabel className="label-align postcode-label label-font-size">
                                                {user.lead_details.from_postcode}
                                              </IonLabel>
                                            </button>
                                          )}
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol size="12">
                                        <IonButton onClick={() => getBookingId(user.id)} routerLink="/viewbooking" routerDirection="none" expand="block" className="booking-details-btn">
                                          View Booking Details
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonCard>
                              </IonCol>
                            ))
                          ) : (
                            <IonGrid>
                              <IonRow>
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 className="fontName dark-nocardh6"
                                    >
                                      Sorry, there is no Bookings for you
                                    </h6>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          );
                        })()
                      )}
                    </IonRow>
                  </IonGrid>
                  {unconfirmedBookingEmptyMsg === true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6 className="fontName dark-nocardh6"
                            >
                              Sorry, there is no Bookings for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  {/* </IonList> */}
                </div>
              )}
              {segment == "expired" && (
                <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                  {/* <IonList className="fontName"> */}
                  {expiredBookingEmptyMsg == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6 className="fontName dark-nocardh6"
                            >
                              Sorry, there is no Bookings for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {isBookingsLoading ? (
                        <div></div>
                      ) : (
                        (() => {
                          const filteredBookings = expiredBooking
                            .filter(user => user.name.toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              user.lead_details.product.category.title.toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              user.lead_details.product.title.toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              user.id.toString().includes(expiredSearchQuery) ||
                              user.lead.mobile.includes(expiredSearchQuery) ||
                              user.lead.email.includes(expiredSearchQuery)
                            );
                          const sortedFilteredBookings = handleExpiredSort(filteredBookings);
                          return sortedFilteredBookings.length > 0 ? (
                            sortedFilteredBookings.map((user, i) => (
                              <IonCol key={i} size-lg="4" size-md="6" size="12">
                                <IonCard className="dark-overall-list">
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size-md='1' size='3'>
                                        <IonAvatar className="booking-img">
                                          <IonImg src={user.lead_details.product.image ? user.lead_details.product.image : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                          {user.postponed == "1" && (
                                            <h6 className="postponed-batch">Postponed</h6>
                                          )}
                                        </IonAvatar>
                                      </IonCol>
                                      <IonCol size-md='11' size="9" className="second-col">
                                        <div className="d-first-div">
                                          <div className="d-flex first-div">
                                            <IonLabel className="dark-first-title">{user.lead_details.product.category.title}</IonLabel>
                                            <IonLabel className="booking-id-label">#{user.id}</IonLabel>
                                          </div>
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/darkPerson.svg" />
                                            <IonLabel className="label-align dark-middle-label label-ellipsis"> {user.lead.firstname} {user.lead.lastname}</IonLabel>
                                          </div>
                                          <br />
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/dark-car-booking.svg" />
                                            <IonLabel className="label-align dark-middle-label label-ellipsis"> {user.lead_details.product.title}</IonLabel>
                                          </div>
                                          <a style={{ textDecoration: "none" }} href={"tel:" + user.lead.mobile}>
                                            <IonImg className="call-icon" src="assets/imgs/icons/phone.svg"></IonImg>
                                          </a>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                    <hr className="dark-hr " />
                                    <IonRow style={{ display: "inline-block", width: "100%" }}>
                                      <IonCol size="7" col-7>
                                        <div className="div-align">
                                          <img className="img-align" src="assets/imgs/icons/darkCalendar.svg" />
                                          <IonLabel className="label-align dark-date-time-color label-font-size  "> {user.lead_details.servicedate} |</IonLabel>
                                        </div>
                                        <div className="div-align">
                                          <img className="img-align" style={{ marginLeft: "5px" }} src="assets/imgs/icons/darkClock.svg" />
                                          <IonLabel className="label-align dark-date-time-color label-font-size"> {user.lead_details.servicetime}</IonLabel>
                                        </div>
                                      </IonCol>
                                      <IonCol className="postcode-col" size="5" col-5>
                                        <div className="div-align">
                                          {latitude == null && longitude == null ? (
                                            <button
                                              style={{ background: "#fff0" }}
                                              onClick={getLocation}
                                            >
                                              <img className="img-align" src="assets/imgs/icons/darkCompass.svg" />
                                              <IonLabel className="label-align postcode-label label-font-size">
                                                {user.lead_details.from_postcode}
                                              </IonLabel>
                                            </button>
                                          ) : (
                                            <button
                                              style={{ background: "#fff0" }}
                                              onClick={() => handleGoogleMapLink(user.lead_details.from_postcode)}
                                            >
                                              <img className="img-align" src="assets/imgs/icons/darkCompass.svg" />
                                              <IonLabel className="label-align postcode-label label-font-size">
                                                {user.lead_details.from_postcode}
                                              </IonLabel>
                                            </button>
                                          )}
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol size="12" size-md="10">
                                        <IonButton expand="block" onClick={() => getBookingId(user.id)} routerLink="/viewbooking" routerDirection="none" className="booking-details-btn">
                                          View Booking Details
                                        </IonButton>
                                      </IonCol>
                                      <IonCol className="m-d-none" size="2">
                                        <IonButton className="print-btn" onClick={() => getPrintBookingId(user.id)} routerLink="/print/booking" routerDirection="none">
                                          <IonIcon
                                              className="fav print-icon"
                                              src="assets/imgs/icons/print.svg"
                                              slot="end"
                                              />
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonCard>
                              </IonCol>
                            ))
                          ) : (
                            <IonGrid>
                              {/* <IonRow>
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 className="fontName dark-nocardh6"
                                    >
                                      Sorry, there is no Bookings for you
                                    </h6>
                                  </div>
                                </IonCol>
                              </IonRow> */}
                            </IonGrid>
                          );
                        })()
                      )}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
            </div>
            <IonPopover
              isOpen={showConfirmPopover.open}
              event={showConfirmPopover.event}
              onDidDismiss={() => setShowConfirmPopover({ open: false, event: undefined })}
            >
              <IonItem button lines="none"
                className="dark-popup-ion-item"
                onClick={() => { setConfirmSortOption('datetime'); setShowConfirmPopover({ open: false, event: undefined }); }}
              >
                {confirmSortOption === 'datetime' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Default</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={confirmSortOption === 'postponed' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                onClick={() => { setConfirmSortOption('postponed'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                {confirmSortOption === 'postponed' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Postponed</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                  className={confirmSortOption === 'returnTrip' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                  onClick={() => { setConfirmSortOption('returnTrip'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                  {confirmSortOption === 'returnTrip' ? (
                      <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                  ) : (
                      <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                  )}
                  <IonLabel className="popup-label">Return Trip</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={confirmSortOption === 'thisWeek' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                onClick={() => { setConfirmSortOption('thisWeek'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                {confirmSortOption === 'thisWeek' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Week</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={confirmSortOption === 'thisMonth' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                onClick={() => { setConfirmSortOption('thisMonth'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                {confirmSortOption === 'thisMonth' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Month</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={confirmSortOption === 'thisYear' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                onClick={() => { setConfirmSortOption('thisYear'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                {confirmSortOption === 'thisYear' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Year</IonLabel>
              </IonItem>
            </IonPopover>
            <IonPopover
              isOpen={showPopover.open}
              event={showPopover.event}
              onDidDismiss={() => setShowPopover({ open: false, event: undefined })}
            >
              <IonItem button lines="none"
                className="dark-popup-ion-item"
                onClick={() => { setSortOption('datetime'); setShowPopover({ open: false, event: undefined }); }}
              >
                {sortOption === 'datetime' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Default</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'status' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                onClick={() => { setSortOption('status'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'status' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Modified</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'postponed' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                onClick={() => { setSortOption('postponed'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'postponed' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Postponed</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                  className={sortOption === 'returnTrip' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                  onClick={() => { setSortOption('returnTrip'); setShowPopover({ open: false, event: undefined }); }}>
                  {sortOption === 'returnTrip' ? (
                      <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                  ) : (
                      <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                  )}
                  <IonLabel className="popup-label">Return Trip</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'unconfirmed' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                onClick={() => { setSortOption('unconfirmed'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'unconfirmed' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Unconfirmed</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'thisWeek' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                onClick={() => { setSortOption('thisWeek'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'thisWeek' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Week</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'thisMonth' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                onClick={() => { setSortOption('thisMonth'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'thisMonth' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Month</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'thisYear' ? 'dark-popup-ion-item' : 'dark-popup-ion-item'}
                onClick={() => { setSortOption('thisYear'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'thisYear' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Year</IonLabel>
              </IonItem>
            </IonPopover>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true} />
            <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="dark-model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className='fontName booking-details-btn'
                        onClick={() => setShowSuspendedModal(false)}
                        expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
      {networkStatus == false && darkModeValue == "lightMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ textAlign: "center" }}>
                Bookings
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="expenses">
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
              <h6 className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true &&
        darkModeValue == "lightMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
              <IonTitle style={{ textAlign: "center" }}>
                Bookings
              </IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/notifications"
                  routerDirection="none"
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/bell.svg"
                    slot="end" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="expenses">
            {segment === "confirmed" && (
              <IonRefresher slot="fixed" onIonRefresh={handleConfirmRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {segment === "unconfirmed" && (
              <IonRefresher slot="fixed" onIonRefresh={handleUnconfirmRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {segment === "expired" && (
              <IonRefresher slot="fixed" onIonRefresh={handleExpiredRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            <div className="search-holder">
              {segment === "confirmed" && (
                <IonGrid style={{ padding: "0 6px" }}>
                  <IonRow>
                    <IonCol size-md='11' size="10">
                      <IonSearchbar
                        placeholder='Confirmed Bookings'
                        style={{ height: "40px" }}
                        className="search-bar"
                        mode="ios"
                        debounce={1000}
                        onIonChange={handleSearch}
                      ></IonSearchbar>
                    </IonCol>
                    <IonCol size-md='1' size="2" style={{ display: "flex" }}>
                      <IonButtons className="sort-btn" onClick={(e) => setShowConfirmPopover({ open: true, event: e.nativeEvent })}>
                        <IonButton>
                          <IonIcon
                            style={{ fontSize: "22px" }}
                            className="fav"
                            src="assets/imgs/icons/sort.svg" />
                          <span className="sort-count-badge">{confirmedSortCount}</span>
                        </IonButton>
                      </IonButtons>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
              {segment === "unconfirmed" && (
                <IonGrid style={{ padding: "0 6px" }}>
                  <IonRow>
                    <IonCol size-md='11' size="10">
                      <IonSearchbar
                        style={{ height: "40px" }}
                        className="search-bar"
                        placeholder='Unconfirmed Bookings'
                        mode="ios"
                        debounce={1000}
                        onIonChange={handleUnconfirmedSearch}
                      ></IonSearchbar>
                    </IonCol>
                    <IonCol size-md='1' size="2" style={{ display: "flex" }}>
                      <IonButtons className="sort-btn" onClick={(e) => setShowPopover({ open: true, event: e.nativeEvent })}>
                        <IonButton>
                          <IonIcon
                            style={{ fontSize: "22px" }}
                            className="fav"
                            src="assets/imgs/icons/sort.svg" />
                          <span className="sort-count-badge">{unConfirmedSortCount}</span>
                        </IonButton>
                      </IonButtons>
                    </IonCol>
                  </IonRow>
                </IonGrid>

              )}
              {segment === "expired" && (
                <IonGrid style={{ padding: "0 6px" }}>
                  <IonRow>
                    <IonCol size="12">
                      <IonSearchbar
                        style={{ height: "40px" }}
                        className="search-bar"
                        placeholder='Expired Bookings'
                        mode="ios"
                        debounce={1000}
                        onIonChange={handleExpiredSearch}
                      ></IonSearchbar>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
            </div>
            <div className="details">
              <div className="segment-holder segment-width">
                <IonSegment className='fontName' value={segment} onIonChange={handleInputChange}>
                  <IonSegmentButton className='fontName custom-segment-button-unconfirmed' value="unconfirmed">
                    <h6 className="light-mode-segements">Unconfirmed <span className='spanCount'>{unConfirmedSortCount}</span></h6>
                  </IonSegmentButton>
                  <IonSegmentButton className='fontName custom-segment-button-confirmed' value="confirmed">
                    <h6 className="light-mode-segements">Confirmed <span className='spanCount'>{confirmedSortCount}</span></h6>
                  </IonSegmentButton>
                  <IonSegmentButton className='fontName custom-segment-button-expired' value="expired">
                    <h6 className="light-mode-segements">Completed <span className='spanCount'>{completedCount}</span></h6>
                  </IonSegmentButton>
                </IonSegment>
              </div>
              {segment === "confirmed" && (
                <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                  {/* <IonList className="fontName"> */}
                  {bookingEmptyMsg == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6 className="fontName nocardh6"
                            >
                              Sorry, there is no Bookings for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {isBookingsLoading ? (
                        <div></div>
                      ) : (
                        (() => {
                          const filteredBookings = booking.filter(user => user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.lead_details.product.category.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.lead_details.product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.id.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.lead.mobile.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.lead.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            user.lead_details.servicedate.includes(searchQuery) ||
                            user.lead_details.servicetime.includes(searchQuery)
                          );
                          const sortedFilteredBookings = handleConfirmedFilter(filteredBookings, confirmSortOption, currentDate);

                          return sortedFilteredBookings.length > 0 ? (
                            sortedFilteredBookings.map((user: any, i: any) => (
                              <IonCol key={i} size-lg="4" size-md="6" size="12">
                                <IonCard className="overall-list" >
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size-md='1' size='3'>
                                        <IonAvatar className="booking-img">
                                          <IonImg src={user.lead_details.product.image ? user.lead_details.product.image : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                          {user.postponed == "1" && (
                                            <h6 className="postponed-batch">Postponed</h6>
                                          )}
                                        </IonAvatar>
                                      </IonCol>
                                      <IonCol size-md='11' size="9" className="second-col">
                                        <div className="d-first-div">
                                          <div className="d-flex first-div">
                                            <IonLabel className="first-title">{user.lead_details.product.category.title}</IonLabel>
                                            <IonLabel className="booking-id-label">#{user.id}</IonLabel>
                                          </div>
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/person.svg" />
                                            <IonLabel className="label-align middle-label label-ellipsis"> {user.lead.firstname} {user.lead.lastname}</IonLabel>
                                          </div>
                                          <br />
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/car-booking.svg" />
                                            <IonLabel className="label-align middle-label label-ellipsis"> {user.lead_details.product.title}</IonLabel>
                                          </div>
                                        </div>
                                        <a style={{ textDecoration: "none" }} href={"tel:" + user.lead.mobile}>
                                          <IonImg className="call-icon" src="assets/imgs/icons/phone.svg"></IonImg>
                                        </a>
                                      </IonCol>
                                    </IonRow>
                                    <hr />
                                    <IonRow style={{ display: "inline-block", width: "100%" }}>
                                      <IonCol size="7" col-7>
                                        <div className="div-align">
                                          <img className="img-align" src="assets/imgs/icons/calendar.svg" />
                                          <IonLabel className="label-align date-time-color label-font-size"> {user.lead_details.servicedate} |</IonLabel>
                                        </div>
                                        <div className="div-align">
                                          <img className="img-align" style={{ marginLeft: "5px" }} src="assets/imgs/icons/clock.svg" />
                                          <IonLabel className="label-align date-time-color label-font-size"> {user.lead_details.servicetime}</IonLabel>
                                        </div>
                                      </IonCol>
                                      <IonCol className="postcode-col" size="5" col-5>
                                        <div className="div-align" onClick={() => handleGoogleMapLink(user.lead_details.from_postcode)}>
                                          <img className="img-align" src="assets/imgs/icons/compass.svg" />
                                          <IonLabel className="label-align postcode-label label-font-size"> {user.lead_details.from_postcode}</IonLabel>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol size="12" size-md="10">
                                        <IonButton expand="block" onClick={() => getBookingId(user.id)} routerLink="/viewbooking" routerDirection="none" className="booking-details-btn">
                                          View Booking Details
                                        </IonButton>
                                      </IonCol>
                                      <IonCol className="m-d-none" size="2">
                                        <IonButton className="print-btn" onClick={() => getPrintBookingId(user.id)} routerLink="/print/booking" routerDirection="none">
                                          <IonIcon
                                              className="fav print-icon"
                                              src="assets/imgs/icons/print.svg"
                                              slot="end"
                                              />
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonCard>
                              </IonCol>
                            ))
                          ) : (
                            <IonGrid>
                              <IonRow>
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 className="fontName nocardh6"
                                    >
                                      Sorry, there is no Bookings for you
                                    </h6>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          );
                        })()

                      )}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {segment == "unconfirmed" && (
                <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                  {/* <IonList className="fontName"> */}
                  {isBookingsLoading == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6 className="fontName nocardh6"
                            >
                              Sorry, there is no Bookings for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}

                  <IonGrid>
                    <IonRow>
                      {isBookingsLoading ? (
                        <div></div>
                      ) : (
                        (() => {
                          const filteredBookings = unconfirmedBooking.filter(user => user.name.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.lead_details.product.category.title.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.lead_details.product.title.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.id.toString().toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.lead.mobile.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.lead.email.toLowerCase().includes(unconfirmedSearchQuery.toLowerCase()) ||
                            user.lead_details.servicedate.includes(unconfirmedSearchQuery) ||
                            user.lead_details.servicetime.includes(unconfirmedSearchQuery)
                          );

                          const sortedFilteredBookings = handleFilter(filteredBookings, sortOption, currentDate);
                          // const unConfirmedCountLength = sortedFilteredBookings.length;
                          // if (unConfirmedCount !== unConfirmedCountLength) {
                          //   setUnConfirmedCount(unConfirmedCountLength);
                          // }
                          // const sortedFilteredBookings = handleSort(filteredStatusBookings, sortOption);
                          return sortedFilteredBookings.length > 0 ? (
                            sortedFilteredBookings.map((user: any, i: any) => (
                              <IonCol key={i} size-lg="4" size-md="6" size="12">
                                <IonCard className="overall-list">
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size-md='1' size='3'>
                                        <div className="ribbon-wrapper">
                                          {user.status == "3" && (
                                            <div className="ribbon">Unconfirmed</div>
                                          )}
                                          {user.status == "4" && (
                                            <div className="ribbon-modified">Modified</div>
                                          )}
                                        </div>
                                        <IonAvatar className="booking-img">
                                          <IonImg src={user.lead_details.product.image ? user.lead_details.product.image : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                          {user.postponed == "1" && (
                                            <h6 className="postponed-batch">Postponed</h6>
                                          )}
                                        </IonAvatar>
                                      </IonCol>
                                      <IonCol size-md='11' size="9" className="second-col">
                                        <div className="d-first-div">
                                          <div className="d-flex first-div">
                                            <IonLabel className="first-title">{user.lead_details.product.category.title}</IonLabel>
                                            <IonLabel className="booking-id-label">#{user.id}</IonLabel>
                                          </div>
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/person.svg" />
                                            <IonLabel className="label-align label-ellipsis middle-label"> {user.lead.firstname} {user.lead.lastname}</IonLabel>
                                          </div>
                                          <br />
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/car-booking.svg" />
                                            <IonLabel className="label-align label-ellipsis middle-label"> {user.lead_details.product.title}</IonLabel>
                                          </div>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                    <hr />
                                    <IonRow style={{ display: "inline-block", width: "100%" }}>
                                      <IonCol size="7" col-7>
                                        <div className="div-align">
                                          <img className="img-align" src="assets/imgs/icons/calendar.svg" />
                                          <IonLabel className="label-align date-time-color label-font-size"> {user.lead_details.servicedate} |</IonLabel>
                                        </div>
                                        <div className="div-align">
                                          <img className="img-align" style={{ marginLeft: "5px" }} src="assets/imgs/icons/clock.svg" />
                                          <IonLabel className="label-align date-time-color label-font-size"> {user.lead_details.servicetime}</IonLabel>
                                        </div>
                                      </IonCol>
                                      <IonCol className="postcode-col" size="5" col-5>
                                        <div className="div-align" onClick={() => handleGoogleMapLink(user.lead_details.from_postcode)}>
                                          <img className="img-align" src="assets/imgs/icons/compass.svg" />
                                          <IonLabel className="label-align postcode-label label-font-size"> {user.lead_details.from_postcode}</IonLabel>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                    <IonRow>
                                      <IonCol size="12">
                                        <IonButton onClick={() => getBookingId(user.id)} routerLink="/viewbooking" routerDirection="none" expand="block" className="booking-details-btn">
                                          View Booking Details
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonCard>
                              </IonCol>
                            ))
                          ) : (
                            <IonGrid>
                              <IonRow>
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 className="fontName nocardh6"
                                    >
                                      Sorry, there is no Bookings for you
                                    </h6>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          );
                        })()
                      )}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {segment == "expired" && (
                <div className="list-holder animate__animated animate__fadeInUp animate__slow">
                  {/* <IonList className="fontName"> */}
                  {expiredBookingEmptyMsg == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Bookings for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {isBookingsLoading ? (
                        <div></div>
                      ) : (
                        (() => {
                          const filteredBookings = expiredBooking
                            .filter(user => user.name.toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              user.lead_details.product.category.title.toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              user.lead_details.product.title.toLowerCase().includes(expiredSearchQuery.toLowerCase()) ||
                              user.id.toString().includes(expiredSearchQuery) ||
                              user.lead.mobile.includes(expiredSearchQuery) ||
                              user.lead.email.includes(expiredSearchQuery)
                            );
                          const sortedFilteredBookings = handleExpiredSort(filteredBookings);
                          return sortedFilteredBookings.length > 0 ? (
                            sortedFilteredBookings.map((user, i) => (
                              <IonCol key={i} size-lg="4" size-md="6" size="12">
                                <IonCard className="overall-list">
                                  <IonGrid>
                                    <IonRow>
                                      <IonCol size-md='1' size='3'>
                                        <IonAvatar className="booking-img">
                                          <IonImg src={user.lead_details.product.image ? user.lead_details.product.image : "assets/imgs/images/no-image.jpg"} alt=""></IonImg>
                                          {user.postponed == "1" && (
                                            <h6 className="postponed-batch">Postponed</h6>
                                          )}
                                        </IonAvatar>
                                      </IonCol>
                                      <IonCol size-md='11' size="9" className="second-col">
                                        <div className="d-first-div">
                                          <div className="d-flex first-div">
                                            <IonLabel className="first-title">{user.lead_details.product.category.title}</IonLabel>
                                            <IonLabel className="booking-id-label">#{user.id}</IonLabel>
                                          </div>
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/person.svg" />
                                            <IonLabel className="label-align middle-label label-ellipsis"> {user.lead.firstname} {user.lead.lastname}</IonLabel>
                                          </div>
                                          <br />
                                          <div className="div-align">
                                            <img className="img-align cat-person-icon" src="assets/imgs/icons/car-booking.svg" />
                                            <IonLabel className="label-align middle-label label-ellipsis"> {user.lead_details.product.title}</IonLabel>
                                          </div>
                                          <a style={{ textDecoration: "none" }} href={"tel:" + user.lead.mobile}>
                                            <IonImg className="call-icon" src="assets/imgs/icons/phone.svg"></IonImg>
                                          </a>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                    <hr />
                                    <IonRow style={{ display: "inline-block", width: "100%" }}>
                                      <IonCol size="7" col-7>
                                        <div className="div-align">
                                          <img className="img-align" src="assets/imgs/icons/calendar.svg" />
                                          <IonLabel className="label-align date-time-color label-font-size  "> {user.lead_details.servicedate} |</IonLabel>
                                        </div>
                                        <div className="div-align">
                                          <img className="img-align" style={{ marginLeft: "5px" }} src="assets/imgs/icons/clock.svg" />
                                          <IonLabel className="label-align date-time-color label-font-size"> {user.lead_details.servicetime}</IonLabel>
                                        </div>
                                      </IonCol>
                                      <IonCol className="postcode-col" size="5" col-5>
                                        <div className="div-align" onClick={() => handleGoogleMapLink(user.lead_details.from_postcode)}>
                                          <img className="img-align" src="assets/imgs/icons/compass.svg" />
                                          <IonLabel className="label-align postcode-label label-font-size"> {user.lead_details.from_postcode}</IonLabel>
                                        </div>
                                      </IonCol>
                                    </IonRow>
                                    <IonRow>
                                    <IonCol size="12" size-md="10">
                                        <IonButton onClick={() => getBookingId(user.id)} routerLink="/viewbooking" routerDirection="none" expand="block" className="booking-details-btn">
                                          View Booking Details
                                        </IonButton>
                                      </IonCol>
                                      <IonCol className="m-d-none" size="2">
                                        <IonButton className="print-btn" onClick={() => getPrintBookingId(user.id)} routerLink="/print/booking" routerDirection="none">
                                          <IonIcon
                                              className="fav print-icon"
                                              src="assets/imgs/icons/print.svg"
                                              slot="end"
                                              />
                                        </IonButton>
                                      </IonCol>
                                    </IonRow>
                                  </IonGrid>
                                </IonCard>
                              </IonCol>
                            ))
                          ) : (
                            <IonGrid>
                              <IonRow>
                                <IonCol>
                                  <div className="fontName nocard">
                                    <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                                    <h6 className="fontName nocardh6"
                                    >
                                      Sorry, there is no Bookings for you
                                    </h6>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          );
                        })()
                      )}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
            </div>
            <IonPopover
              isOpen={showConfirmPopover.open}
              event={showConfirmPopover.event}
              onDidDismiss={() => setShowConfirmPopover({ open: false, event: undefined })}
            >
              <IonItem button lines="none"
                className="popup-ion-item"
                onClick={() => { setConfirmSortOption('datetime'); setShowConfirmPopover({ open: false, event: undefined }); }}
              >
                {confirmSortOption === 'datetime' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Default</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={confirmSortOption === 'postponed' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setConfirmSortOption('postponed'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                {confirmSortOption === 'postponed' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Postponed</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                  className={confirmSortOption === 'returnTrip' ? 'popup-ion-item' : 'popup-ion-item'}
                  onClick={() => { setConfirmSortOption('returnTrip'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                  {confirmSortOption === 'returnTrip' ? (
                      <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                  ) : (
                      <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                  )}
                  <IonLabel className="popup-label">Return Trip</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={confirmSortOption === 'thisWeek' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setConfirmSortOption('thisWeek'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                {confirmSortOption === 'thisWeek' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Week</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={confirmSortOption === 'thisMonth' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setConfirmSortOption('thisMonth'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                {confirmSortOption === 'thisMonth' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Month</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={confirmSortOption === 'thisYear' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setConfirmSortOption('thisYear'); setShowConfirmPopover({ open: false, event: undefined }); }}>
                {confirmSortOption === 'thisYear' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Year</IonLabel>
              </IonItem>
            </IonPopover>
            <IonPopover
              isOpen={showPopover.open}
              event={showPopover.event}
              onDidDismiss={() => setShowPopover({ open: false, event: undefined })}
            >
              <IonItem button lines="none"
                className="popup-ion-item"
                onClick={() => { setSortOption('datetime'); setShowPopover({ open: false, event: undefined }); }}
              >
                {sortOption === 'datetime' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Default</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'status' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setSortOption('status'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'status' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Modified</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'postponed' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setSortOption('postponed'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'postponed' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Postponed</IonLabel>
              </IonItem>
              <IonItem button lines="none"
    className={sortOption === 'returnTrip' ? 'popup-ion-item' : 'popup-ion-item'}
    onClick={() => { setSortOption('returnTrip'); setShowPopover({ open: false, event: undefined }); }}>
    {sortOption === 'returnTrip' ? (
        <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
    ) : (
        <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
    )}
    <IonLabel className="popup-label">Return Trip</IonLabel>
</IonItem>
              <IonItem button lines="none"
                className={sortOption === 'unconfirmed' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setSortOption('unconfirmed'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'unconfirmed' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">Unconfirmed</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'thisWeek' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setSortOption('thisWeek'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'thisWeek' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Week</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'thisMonth' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setSortOption('thisMonth'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'thisMonth' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Month</IonLabel>
              </IonItem>
              <IonItem button lines="none"
                className={sortOption === 'thisYear' ? 'popup-ion-item' : 'popup-ion-item'}
                onClick={() => { setSortOption('thisYear'); setShowPopover({ open: false, event: undefined }); }}>
                {sortOption === 'thisYear' ? (
                  <img slot="start" src="assets/imgs/icons/checked.svg" alt="Checked" />
                ) : (
                  <img slot="start" src="assets/imgs/icons/unCheck.svg" alt="Unchecked" />
                )}
                <IonLabel className="popup-label">This Year</IonLabel>
              </IonItem>
            </IonPopover>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true} />
            <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonToast
                                isOpen={showToast}
                                onDidDismiss={() => setShowErrorToast(false)}
                                message={errorMessage}
                                duration={3000}
                                color="danger" />
          </IonContent>
        </>
      }
    </IonPage >
  );
};

export default Expenses;
