import React, { useRef, useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonLabel, IonLoading, IonPage, IonRow, IonTitle, useIonViewWillEnter } from '@ionic/react';
import { apiConfig, axiosInstance } from '../../apiConfig';
import "./PrintPreview.css";

const PrintPreview: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const [supendedMsg, setSupendedMsg] = useState('');
    const [supendedTitle, setSupendedTitle] = useState('');
    const [bookingId, setBookingId] = useState('');
    const [bookingStatus, setBookingStatus] = useState('');
    const [exportBooking, setExportBooking] = useState<any>();
    const [networkStatus, setNetworkStatus] = useState<any>(true);

    

    useIonViewWillEnter(() => {
        localStorage.setItem("confirmed", "confirmed");
        setLoading(true);
        const endpoint = "/exportBooking";
        axiosInstance.get(`${apiConfig.baseUrl}${endpoint}/${localStorage.getItem("printBookingId")}`, { headers: apiConfig.getHeaders(endpoint) })
            .then(response => {
                if (response.data.suspendstatus === false) {
                    setSupendedMsg(response.data.message);
                    setSupendedTitle(response.data.title);
                    setShowSuspendedModal(true);
                    setLoading(false);
                } else {
                    console.log("response.data.data", response.data.data.lead.firstname);
                    const exportBookingData = response.data.data;
    
                  
                // Function to format address with postal code at the end
                const formatAddressWithPostalCode = (address: any) => {
                    if (!address) return '';
                    const addressParts = address.split(", ");
                    if (addressParts.length < 2) {
                        return addressParts.join(",\n");
                    }

                    // Move last part to the second last position (city before postal code)
                    const postalCode = addressParts[addressParts.length - 2]; // Second last part
                    const city = addressParts[addressParts.length - 1]; // Last part
                    const reorderedParts = [
                        ...addressParts.slice(0, addressParts.length - 2), // All but last two parts
                        city, // City name
                        postalCode, // Postal code
                    ];

                    return reorderedParts.join(",\n");
                };

                // Format from_address
                if (exportBookingData.lead_details && exportBookingData.lead_details.from_address) {
                    exportBookingData.lead_details.formatted_from_address = formatAddressWithPostalCode(
                        exportBookingData.lead_details.from_address
                    );
                }

                // Format to_address
                if (exportBookingData.lead_details && exportBookingData.lead_details.to_address) {
                    exportBookingData.lead_details.formatted_to_address = formatAddressWithPostalCode(
                        exportBookingData.lead_details.to_address
                    );
                }

                if (response.data.expired === true) {
                    localStorage.setItem("expired", "expired");
                } else {
                    localStorage.setItem("confirmed", "confirmed");
                }
    
                    setExportBooking(exportBookingData);
                    setTimeout(() => {
                        window.print();
                    }, 1000);
                    setBookingId(exportBookingData.id);
                    setBookingStatus(exportBookingData.status);
                    setLoading(false);
                }
                setTimeout(() => {
                    if (loading === true) {
                        setLoading(false);
                    }
                }, 10000);
            })
            .catch(error => {
                console.log(error);
                if (error.code === "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
            });
    }, []);


  return (
    <IonPage>
        <IonContent fullscreen >
     
     <IonGrid className='top-grid'>
                <IonRow>
                    <IonCol className='top-grid-col' size='12'>
                    <IonImg className="top-logo" src="assets/imgs/images/AFH-Logo-White.png"></IonImg>
                    <IonButton  routerLink="/tabs/bookings" routerDirection="none" className='top-grid-btn'><IonIcon src='assets/imgs/icons/weui_back-outlined.svg'></IonIcon><span>BACK TO BOOKINGS</span></IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <div className='top-div'>
            {exportBooking && 
            <IonGrid className='second-grid'>
            <IonRow>
                <IonCol size='12'>
                    <div className='top-title-d-flex'>
                    <IonTitle style={{textAlign:"left"}} className='bkg-id-title'>Booking Ref: <b>{bookingId}</b></IonTitle>
                    <IonTitle style={{textAlign:"right"}} className='bkg-id-title'>Partner: <b>{exportBooking.lead_details.product.partner.companyname}</b></IonTitle>
                    </div>
                </IonCol>
                <IonCol size='6'>
                    <div className='innner-col'>
                        <div className='title-div'>
                    <IonImg className='title-icon' src="assets/imgs/icons/customer_details.svg"></IonImg>
                    <IonTitle className='top-ion-title'>Customer Details</IonTitle>
                    </div>
                <table>
                    <tr>
                        <td className='td-p'>Name</td>
                        <td className='td-p'>{exportBooking.lead.firstname} {exportBooking.lead.lastname}</td>
                    </tr>
                    <tr>
                        <td className='td-p'>Mobile Number</td>
                        <td className='td-p'>{exportBooking.lead.mobile}</td>
                    </tr>
                    <tr>
                        <td className='td-p'>Post Code</td>
                        <td className='td-p'>{exportBooking.lead_details.from_postcode}</td>
                    </tr>
                </table>
                    </div>
                </IonCol>
                <IonCol size='6' className='inner-small-col'>
                    <div className='innner-col'>
                    <div className='title-div'>
                    <IonImg className='title-icon' src="assets/imgs/icons/collection_address.svg"></IonImg>
                <IonTitle className='top-ion-title'>Collection Address</IonTitle>
                </div>
                <table>
                    <tr>
                        <td style={{ whiteSpace: 'pre-line' }} className='td-p'><b>{exportBooking?.lead_details?.formatted_from_address}</b></td>
                    </tr>
                </table>
                </div>
                </IonCol>
                <IonCol size='6' className='bkg-col'>
                    <div className='innner-col'>
                    <div className='title-div'>
                    <IonImg className='title-icon' src="assets/imgs/icons/booking_details.svg"></IonImg>
                    <IonTitle className='top-ion-title'>Booking Details</IonTitle>
                    </div>
                <table>
                    <tr>
                        <td className='td-p'>Vehicle Type</td>
                        <td className='td-p'>{exportBooking.lead_details.product.title}</td>
                    </tr>
                    {exportBooking.lead_inputs && (
                        exportBooking.lead_inputs.map((lead: any, i: any) => {
                           return (
                           <>
                              <tr>
                                 <td className='td-p' key={i}>
                                      {lead.input_property.title}
                                  </td>
                                 <td className='td-p'>
                                    <IonLabel className="value-color">{lead.inputvalue}</IonLabel>
                                 </td>
                               </tr>
                              </>
                                 );
                                   })
                                  )}
                    {/* <tr>
                        <td className='td-p'>Vehicle Colour</td>
                        <td className='td-p'>: -</td>
                    </tr> */}
                    <tr>
                        <td className='td-p'>Trip Type</td>
                        <td className="td-p">{exportBooking.lead_details.servicetype.title}</td>
                    </tr>
                    <tr>
                        <td className='td-p'>Date</td>
                        <td className='td-p'>{exportBooking.lead_details.servicedate}</td>
                    </tr>
                    <tr>
                        <td className='td-p'>Time (24 hour format)</td>
                        <td className='td-p'>{exportBooking.lead_details.servicetime}</td>
                    </tr>
                    {exportBooking.lead_details.isReturnTrip == "true" && (
                                                                        <>
                                                                            <tr>
                                                                                <td className='td-p'>Return Date</td>
                                                                                <td className='td-p'>{exportBooking.lead_details.returndate}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className='td-p'>Return Time (24 hour format)</td>
                                                                                <td className='td-p'>{exportBooking.lead_details.returntime}</td>
                                                                            </tr>
                                                                        </>
                                                                    )}
                </table>
                    </div>
                </IonCol>
                <IonCol size='6' className='bkg-col'>
                <div className='innner-col'>
                        <div>
                        <div className='title-div'>
                    <IonImg className='title-icon' src="assets/imgs/icons/destiantion_address.svg"></IonImg>
                        <IonTitle className='top-ion-title'>Destination Address</IonTitle>
                        </div>
                <table>
                    <tr>
                        <td style={{ whiteSpace: 'pre-line' }} className='td-p'><b>{exportBooking?.lead_details?.formatted_to_address}</b></td>
                    </tr>
                </table>
                    </div>
                        </div>
                        <div className='innner-col inner-col-mt'>
                <div className='title-div'>
                    <IonImg className='title-icon' src="assets/imgs/icons/additional_information.svg"></IonImg>
                    <IonTitle className='top-ion-title'>Additional Info</IonTitle>
                    </div>
                    <table>
                        <tr>
                            <td className='td-p'>Driver Notes</td>
                            <td className='td-p'>{exportBooking.partnernotes}</td>
                        </tr>
                    </table>
                    </div>
                    <div className='innner-col inner-col-mt'>
                       <div className='title-div'>
                    <IonImg className='title-icon' src="assets/imgs/icons/admin_details.svg"></IonImg>
                    <IonTitle className='top-ion-title'>Admin Details</IonTitle>
                    </div>
                    <table>
                        {/* <tr>
                            <td className='td-p'>Partner Name</td>
                            <td className='td-p'>{exportBooking.lead_details.product.partner.companyname}</td>
                        </tr> */}
                        <tr>
                            <td className='td-p'>Balance to be Collected</td>
                            <td className='td-p'>£ {(exportBooking.lead_quote_details.partnerprice).toFixed(2)}</td>
                        </tr>
                    </table>
                    </div>
                </IonCol>
            </IonRow>
        </IonGrid>
            }
     
     </div>
                            <IonLoading
                                isOpen={loading}
                                spinner="circular"
                                translucent={true}
                            />
                            </IonContent>
                            </IonPage>
  );
};

export default PrintPreview;
