import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonTitle,
    IonToast,
    IonToolbar,
    useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { useLocation } from 'react-router-dom';
import { axiosInstance, apiConfig } from "../../apiConfig";
import "../Profile/Profile.css";
import { Network } from '@capacitor/network';
interface OwnProps extends RouteComponentProps { }
const Activehealthstatus: React.FC = () => {
    const darkModeValue = localStorage.getItem("darkMode");
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [backdropVisible, setBackdropVisible] = useState(false);
    const [ASHstatus, setASHstatus] = useState<any>();

    const [showSuspendedModal, setShowSuspendedModal] = useState(false);
    const [supendedMsg, setSupendedMsg] = useState('');
    const [supendedTitle, setSupendedTitle] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [showToast, setShowToast] = useState(false);

    const [networkStatus, setNetworkStatus] = useState<any>(true);

    const location = useLocation();
    const isHomePage = location.pathname;
    localStorage.setItem("locationPage", isHomePage);

    useIonViewWillEnter(() => {
        if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
            history.push('/login');
          }
        AHSFunction();
    }, []);
    useEffect(() => {
        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status.connected);
            setNetworkStatus(status.connected);
            // if (status.connected == true) {
            //     AHSFunction();
            // }
        });
        // logCurrentNetworkStatus();
    }, []);
    const AHSFunction = () => {
        setLoading(true);
        setBackdropVisible(true);
        const endpoint = "/health-status"
        axiosInstance.get(`${apiConfig.baseUrl}${endpoint}`,{ headers: apiConfig.getHeaders(endpoint) })
            .then(response => {
                if(response.data.message == "User not found") {
                    setLoading(false);
                    setBackdropVisible(false);
                    history.push('/login');
                  } else {
                if (response.data.suspendstatus == false) {
                    setSupendedMsg(response.data.message);
                    setSupendedTitle(response.data.title);
                    setShowSuspendedModal(true);
                    setLoading(false);
                    setBackdropVisible(false);
                } else {
                    setASHstatus(response.data);
                    setLoading(false);
                    setBackdropVisible(false);

                }
            }
                setTimeout(() => {
                    if (loading == true) {
                        setLoading(false);
                        setBackdropVisible(false);
                        setErrorMessage("Something went wrong on API please try again");
                        setShowToast(true);
                    }
                }, 10000);
            })
            .catch(error => {
                if (error.code == "ERR_NETWORK") {
                    setNetworkStatus(false);
                } else {
                    setNetworkStatus(true);
                }
                setLoading(false);
                setBackdropVisible(false);
            });

    }
    const goBack = () => {
        history.goBack();
    };
    return (
        <IonPage>

            {networkStatus == false && darkModeValue == "darkMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="profile-header-toolbar center-div">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrowWhite.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ color: "#fff", fontSize: "16px" }} className="ion-text-center">Account Health Status</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bellWhite.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile dark-profile">
                        <div className="no-network-div center-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
                            <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true &&
                darkModeValue == "darkMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="profile-header-toolbar center-div">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrowWhite.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ color: "#fff", fontSize: "16px" }} className="ion-text-center">Account Health Status</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bellWhite.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile dark-profile">
                        <div className="center-div">
                            <div className="background-holder"></div>



                            {ASHstatus &&
                                <>
                                    {/* {ASHstatus.accounthealth == 1 &&
                                        <IonImg className="ahs-img" src="assets/imgs/images/Suspended-Dark.png"></IonImg>
                                    }
                                    {ASHstatus.accounthealth == 2 &&
                                        <IonImg className="ahs-img" src="assets/imgs/images/Poor-Dark.png"></IonImg>
                                    }
                                    {ASHstatus.accounthealth == 3 &&
                                        <IonImg className="ahs-img" src="assets/imgs/images/Under_Review-Dark.png"></IonImg>
                                    }
                                    {ASHstatus.accounthealth == 4 && */}
                                    <IonImg className="ahs-img" src="assets/imgs/images/Good-Dark.png"></IonImg>
                                    {/* }
                                    {ASHstatus.accounthealth == 5 &&
                                        <IonImg className="ahs-img" src="assets/imgs/images/Excellent-Dark.png"></IonImg>
                                    }
                                    {ASHstatus.accounthealth == null &&
                                        <IonImg className="ahs-img" src="assets/imgs/images/Excellent-Dark.png"></IonImg>
                                    } */}
                                    <div className="dark-bottom-section ion-padding-horizontal">
                                        <div className="general animate__animated animate__fadeInUp">
                                            <h5 className="dark-bottom-section">General</h5>
                                            <div className="gen-list">
                                                <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/dark-all-booking.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="dark-profile-label">All Bookings</IonLabel>
                                                    <IonLabel slot="end" className="dark-profile-label">{ASHstatus.allbookingcount}</IonLabel>
                                                </IonItem>
                                                <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/dark-confirm.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="dark-profile-label">Confirmed</IonLabel>
                                                    <IonLabel slot="end" className="dark-profile-label">{ASHstatus.confirmedbookingscount}</IonLabel>
                                                </IonItem>
                                                <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/dark-un.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="dark-profile-label">Unconfirmed</IonLabel>
                                                    <IonLabel slot="end" className="dark-profile-label">{ASHstatus.unconfirmedbookingscount}</IonLabel>
                                                </IonItem>
                                                <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/dark-cb.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="dark-profile-label">Completed Bookings</IonLabel>
                                                    <IonLabel slot="end" className="dark-profile-label">{ASHstatus.completedcount}</IonLabel>
                                                </IonItem>
                                                <IonItem style={{ marginBottom: "10px" }} lines="none" className="profile-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/dark-cancel.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="dark-profile-label">Rejected Bookings</IonLabel>
                                                    <IonLabel slot="end" className="dark-profile-label">{ASHstatus.rejectcount}</IonLabel>
                                                </IonItem>
                                            </div>
                                        </div>
                                    </div></>
                            }
                        </div>
                        <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
                            <IonContent className="dark-model-bg">
                                <IonGrid className='cnfmModelGrid'>
                                    <IonRow>
                                        <IonCol size="12" col-12>
                                            <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                        </IonCol>
                                        <IonCol size='12' col-12>
                                            <IonButton
                                                routerLink="/login"
                                                routerDirection="none"
                                                style={{ width: "100%", margin: "auto" }}
                                                className='fontName booking-details-btn'
                                                onClick={() => setShowSuspendedModal(false)}
                                                expand="block">
                                                Back to Login
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonContent>
                        </IonModal>
                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true}
                        />
                    </IonContent>
                </>
            }
            {networkStatus == false && darkModeValue == "lightMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="profile-header-toolbar center-div">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrowWhite.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ color: "#fff", fontSize: "16px" }} className="ion-text-center">Account Health Status</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bellWhite.svg"
                                        slot="end" />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile">
                        <div className="no-network-div center-div">
                            <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
                            <h6 className='fontName'>Check your internet connection and try again</h6>
                        </div>
                    </IonContent>
                </>
            }
            {networkStatus == true && darkModeValue == "lightMode" &&
                <>
                    <IonHeader className="ion-no-border">
                        <IonToolbar className="profile-header-toolbar center-div">
                            <IonButtons slot="start">
                                <IonButton
                                    className="end-btn"
                                    onClick={goBack}
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/backArrowWhite.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                            <IonTitle style={{ color: "#fff", fontSize: "16px" }} className="ion-text-center">Account Health Status</IonTitle>
                            <IonButtons slot="end">
                                <IonButton
                                    className="end-btn"
                                    routerLink="/notifications"
                                    routerDirection="none"
                                >
                                    <IonIcon
                                        className="fav header-icons"
                                        src="assets/imgs/icons/bellWhite.svg"
                                        slot="end"
                                    />
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent fullscreen className="profile">
                        <div className="center-div">
                            <div className="background-holder"></div>
                            {ASHstatus &&
                                <>
                                    {/* {ASHstatus.accounthealth == 1 &&
                                        <IonImg className="ahs-img" src="assets/imgs/images/Suspended.png"></IonImg>
                                    }
                                    {ASHstatus.accounthealth == 2 &&
                                        <IonImg className="ahs-img" src="assets/imgs/images/Poor.png"></IonImg>
                                    }
                                    {ASHstatus.accounthealth == 3 &&
                                        <IonImg className="ahs-img" src="assets/imgs/images/Under Review.png"></IonImg>
                                    }
                                    {ASHstatus.accounthealth == 4 && */}
                                    <IonImg className="ahs-img" src="assets/imgs/images/Good.png"></IonImg>
                                    {/* }
                                    {ASHstatus.accounthealth == 5 &&
                                        <IonImg className="ahs-img" src="assets/imgs/images/Excellent.png"></IonImg>
                                    }
                                    {ASHstatus.accounthealth == null &&
                                        <IonImg className="ahs-img" src="assets/imgs/images/Excellent.png"></IonImg>
                                    } */}
                                    <div className="bottom-section ion-padding-horizontal">
                                        <div className="general animate__animated animate__fadeInUp">
                                            <h5>General</h5>
                                            <div className="gen-list">
                                                <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/ahsAllbooking.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="profile-label">All Bookings</IonLabel>
                                                    <IonLabel slot="end" className="profile-label">{ASHstatus.allbookingcount}</IonLabel>
                                                </IonItem>
                                                <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/ahsCnfm.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="profile-label">Confirmed</IonLabel>
                                                    <IonLabel slot="end" className="profile-label">{ASHstatus.confirmedbookingscount}</IonLabel>
                                                </IonItem>
                                                <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/ahsUncnfm.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="profile-label">Unconfirmed</IonLabel>
                                                    <IonLabel slot="end" className="profile-label">{ASHstatus.unconfirmedbookingscount}</IonLabel>
                                                </IonItem>
                                                <IonItem lines="none" className="profile-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/ahsCB.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="profile-label">Completed Bookings</IonLabel>
                                                    <IonLabel slot="end" className="profile-label">{ASHstatus.completedcount}</IonLabel>
                                                </IonItem>
                                                <IonItem style={{ marginBottom: "10px" }} lines="none" className="profile-ion-item mrgn-btn-ahs">
                                                    <div className="thumb" slot="start">
                                                        <img src="assets/imgs/icons/ahsRB.svg" alt="" />
                                                    </div>
                                                    <IonLabel className="profile-label">Rejected Bookings</IonLabel>
                                                    <IonLabel slot="end" className="profile-label">{ASHstatus.rejectcount}</IonLabel>
                                                </IonItem>
                                            </div>
                                        </div>
                                    </div></>
                            }
                        </div>
                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true}
                        />
                        <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
                            <IonContent className="model-bg">
                                <IonGrid className='cnfmModelGrid'>
                                    <IonRow>
                                        <IonCol size="12" col-12>
                                            <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                                        </IonCol>
                                        <IonCol size="12" col-12>
                                            <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                                        </IonCol>
                                        <IonCol size='12' col-12>
                                            <IonButton
                                                routerLink="/login"
                                                routerDirection="none"
                                                style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                                                Back to Login
                                            </IonButton>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonContent>
                        </IonModal>
                        <IonLoading
                            isOpen={loading}
                            spinner="circular"
                            translucent={true}
                        />
                        <IonToast
                            isOpen={showToast}
                            onDidDismiss={() => setShowToast(false)}
                            message={errorMessage}
                            duration={3000}
                            color="danger"
                        />
                    </IonContent>
                </>
            }
        </IonPage>
    );
};

export default Activehealthstatus;
