import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToast,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./Notifications.css";
import {apiConfig, axiosInstance} from "../../apiConfig";
import axios from "axios";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Network } from '@capacitor/network';

const Notifications: React.FC = () => {
  const darkModeValue = localStorage.getItem("darkMode");


  const history = useHistory();
  const [segment, setSegment] = useState('booking');
  const [loading, setLoading] = useState(false);
  const [backdropVisible, setBackdropVisible] = useState(false);
  const [notificationList, setNotificationList] = useState<Array<any>>([]);
  const [notificationGeneral, setNotificationGeneral] = useState<Array<any>>([]);
  const [notificationBooking, setNotificationBooking] = useState<Array<any>>([]);

  const [recent, setRecent] = useState<Array<any>>([]);
  const [today, setToday] = useState<Array<any>>([]);
  const [yesterday, setYesterday] = useState<Array<any>>([]);
  const [thisWeek, setThisWeek] = useState<Array<any>>([]);

  const [showSuspendedModal, setShowSuspendedModal] = useState(false);
  const [NotificationsAvail, setNotificationsAvail] = useState(false);
  const [NotificationsGeneralAvail, setNotificationsGeneralAvail] = useState(false);
  const [NotificationsAllAvail, setNotificationsAllAvail] = useState(false);
  const [supendedMsg, setSupendedMsg] = useState('');
  const [supendedTitle, setSupendedTitle] = useState('');

  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');



  const ONE_HOUR_IN_MS = 60 * 60 * 1000;
  const ONE_DAY_IN_MS = 24 * ONE_HOUR_IN_MS;

  const currentDate = new Date();


  const location = useLocation();
  const isHomePage = location.pathname;
  localStorage.setItem("locationPage", isHomePage);

  const [networkStatus, setNetworkStatus] = useState<any>(true);
  useEffect(() => {
    if(localStorage.getItem("isLoggedIn") == "" || localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") == undefined || localStorage.getItem("isLoggedIn") == "no") {
      history.push('/login');
    }
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status.connected);
      setNetworkStatus(status.connected);
    });
  }, []);

  useIonViewWillEnter(() => {
    if (segment == "booking") {
      notificationListBookings();
    } else if (segment == "general") {
      notificationListGeneral();
    } else {
      notificationListAll();
    }
  }, []);
  // useEffect(() => {
  //   notificationSort(currentDate);
  // }, [notificationList, currentDate]);

  const notificationListBookings = () => {
    setLoading(true);
    setBackdropVisible(true);
    const endpoint = "/listnotification"
     axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {
      "status": "1"
    }, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if(response.data.message == "User not found") {
          setLoading(false);
          setBackdropVisible(false);
          history.push('/login');
        } else {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          if (response.data.data.length !== 0) {
            setNotificationBooking(response.data.data);
            setNotificationsAvail(false);
            setLoading(false);
            notificationSort(response.data.data, currentDate);
            setBackdropVisible(false);
          } else {
            setNotificationsAvail(true);
            setNotificationBooking(response.data.data);
            setLoading(false);
            notificationSort(response.data.data, currentDate);
            setBackdropVisible(false);
          }
          setLoading(false);
          setBackdropVisible(false);
        }
      }
        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });

  }

  const handleBookingsRefresh = async (event: CustomEvent) => {
    setLoading(false);
    notificationListBookings();
    setTimeout(() => {
      console.log("loading", loading);
      if (loading == true) {
        event.detail.complete();
      }
    }, 1000);

  }

  const notificationListGeneral = () => {
    // setNotificationGeneral([]);
    setLoading(true);
    setBackdropVisible(true);
    const endpoint = "/listnotification"
     axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {
      "status": "2",
    }, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if(response.data.message == "User not found") {
          setLoading(false);
          setBackdropVisible(false);
          history.push('/login');
        } else {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          if (response.data.data.length !== 0) {
            setNotificationGeneral(response.data.data);
            setLoading(false);
            setNotificationsGeneralAvail(false);
            setBackdropVisible(false);
          } else {
            setNotificationsGeneralAvail(true);
            setNotificationGeneral(response.data.data);
            setLoading(false);
            setBackdropVisible(false);
          }
          setLoading(false);
          setBackdropVisible(false);
        }
      }
        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });

  }

  const handleGeneralRefresh = async (event: CustomEvent) => {
    setLoading(false);
    notificationListGeneral();
    setTimeout(() => {
      console.log("loading", loading);
      if (loading == true) {
        event.detail.complete();

      }
    }, 1000);

  }
  const notificationListAll = () => {
    // setNotificationList([]);
    setLoading(true);
    setBackdropVisible(true);
    const endpoint = "/listnotification"
     axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {}, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if(response.data.message == "User not found") {
          setLoading(false);
          setBackdropVisible(false);
          history.push('/login');
        } else {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          if (response.data.data.length !== 0) {
            setNotificationList(response.data.data);
            setLoading(false);
            setNotificationsAllAvail(false);
            setBackdropVisible(false);
          } else {
            setNotificationList(response.data.data);
            setLoading(false);
            setNotificationsAllAvail(true);
            setBackdropVisible(false);
          }
          setLoading(false);
          setBackdropVisible(false);
        }
      }
        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });

  }

  const handleAllRefresh = async (event: CustomEvent) => {
    setLoading(false);
    notificationListAll();
    setTimeout(() => {
      console.log("loading", loading);
      if (loading == false) {
        event.detail.complete();

      }
    }, 1000);

  }
  const notificationReadStatus = (id: any) => {
    setLoading(true);
    setBackdropVisible(true);
    const endpoint = "/notificationReadStatus"
     axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {
      "id": id,
      "readStatus": 0,
    }, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          if (response.data.status == true) {
            if (segment == "booking") {
              notificationListBookings();
            } else if (segment == "general") {
              notificationListGeneral();
            } else {
              notificationListAll();
            }
            setLoading(false);
            setBackdropVisible(false);
          } else {
            setLoading(false);
            setBackdropVisible(false);
          }
        }
        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });

  }

  const redirectND = (id: any) => {
    history.push({
      pathname: '/notificationDetail',
      state: { data: id }
    });
  }

  const notificationRoute = (id: any) => {
    localStorage.setItem("bookingId", id.booking_id);
    setLoading(true);
    setBackdropVisible(true);
    const endpoint = "/checkpartner"
     axiosInstance.post(`${apiConfig.baseUrl}${endpoint}`, {
      "bookingId": localStorage.getItem("bookingId"),
    }, { headers: apiConfig.getHeaders(endpoint) })
      .then(response => {
        if (response.data.suspendstatus == false) {
          setSupendedMsg(response.data.message);
          setSupendedTitle(response.data.title);
          setShowSuspendedModal(true);
          setLoading(false);
          setBackdropVisible(false);
        } else {
          if (response.data.status == true) {
            history.push('/viewbooking');
            setLoading(false);
            setBackdropVisible(false);
          } else {
            setLoading(false);
            setBackdropVisible(false);
            setErrorMessage("This Booking is not your's");
            setShowToast(true);
          }
        }
        setTimeout(() => {
          if (loading == true) {
            setLoading(false);
            setBackdropVisible(false);
            // setErrorMessage("Something went wrong on API please try again");
            // setShowToast(true);
          }
        }, 10000);
      })
      .catch(error => {
        console.log(error);
        if (error.code == "ERR_NETWORK") {
          setNetworkStatus(false);
        } else {
          setNetworkStatus(true);
        }
        setLoading(false);
        setBackdropVisible(false);
      });
  }
  const notificationSort = (notificationData: any[], currentDate: any) => {
    const notificationListWithDates = notificationData.map(user => ({
      ...user,
      formatted_date: new Date(user.formatted_date)
    }));

    const recentNotifications = notificationListWithDates.filter(user => {
      const notificationDate = user.formatted_date;
      return currentDate - notificationDate <= ONE_HOUR_IN_MS;
    });

    const todayNotifications = notificationListWithDates.filter(user => {
      const notificationDate = user.formatted_date;
      const isSameDay = notificationDate.toDateString() === currentDate.toDateString();
      return isSameDay && currentDate - notificationDate > ONE_HOUR_IN_MS;
    });

    const yesterdayNotifications = notificationListWithDates.filter(user => {
      const notificationDate = user.formatted_date;
      const isYesterday = notificationDate.toDateString() === new Date(currentDate - ONE_DAY_IN_MS).toDateString();
      return isYesterday;
    });
    const thisWeekNotifications = notificationListWithDates.filter(user => {
      const notificationDate = user.formatted_date;
      const timeDiff = currentDate - notificationDate;
      const daysSinceNotification = timeDiff / ONE_DAY_IN_MS;
      return daysSinceNotification <= 7 && daysSinceNotification > 1;
    });
    setRecent(recentNotifications);
    setToday(todayNotifications);
    setYesterday(yesterdayNotifications);
    setThisWeek(thisWeekNotifications);
  }
  // const notificationSort = (currentDate: any) => {
  //   const notificationListWithDates = notificationList.map(user => ({
  //     ...user,
  //     formatted_date: new Date(user.formatted_date)
  //   }));

  //   // Filter notifications into different categories
  //   const recentNotifications = notificationListWithDates.filter(user => {
  //     const notificationDate = user.formatted_date;
  //     return currentDate - notificationDate <= ONE_HOUR_IN_MS;
  //   });
  //   setRecent(recentNotifications);

  //   const todayNotifications = notificationListWithDates.filter(user => {
  //     const notificationDate = user.formatted_date;
  //     const isSameDay = notificationDate.toDateString() === currentDate.toDateString();
  //     return isSameDay && currentDate - notificationDate > ONE_HOUR_IN_MS;
  //   });
  //   setToday(todayNotifications);
  //   const yesterdayNotifications = notificationListWithDates.filter(user => {
  //     const notificationDate = user.formatted_date;
  //     const isYesterday = notificationDate.toDateString() === new Date(currentDate - ONE_DAY_IN_MS).toDateString();
  //     return isYesterday;
  //   });
  //   setYesterday(yesterdayNotifications);
  //   const thisWeekNotifications = notificationListWithDates.filter(user => {
  //     const notificationDate = user.formatted_date;
  //     const timeDiff = currentDate - notificationDate;
  //     const daysSinceNotification = timeDiff / ONE_DAY_IN_MS;
  //     return daysSinceNotification <= 7 && daysSinceNotification > 1;
  //   });
  // }

  const handleInputChange = (event: any) => {
    // setIsBookingsLoading(true);
    setSegment(event.detail.value);
    if (event.detail.value == "booking") {
      notificationListBookings();
    } else if (event.detail.value == "general") {
      notificationListGeneral();
    } else {
      notificationListAll();
    }
  };
  const goBack = () => {
    history.goBack();
  };
  function parseDate(input: string): Date | null {
    if (typeof input !== 'string') {
      console.error('Input must be a valid string.');
      return null; // Handle this error more gracefully
    }

    const parts = input.match(/(\d+)/g);
    if (!parts || parts.length < 5) {
      console.error('Invalid date format');
      return null; // Handle this error more gracefully
    }

    const year = parseInt(parts[2]);
    const month = parseInt(parts[1]) - 1;
    const day = parseInt(parts[0]);
    const hour = parseInt(parts[3]);
    const minute = parseInt(parts[4]);

    return new Date(year, month, day, hour, minute);
  }

  const handleSort = (notificationList: any[]): any[] => {
    const sortedList = [...notificationList];
    sortedList.sort((a, b) => {
      const dateA = parseDate(a.formatted_date);
      const dateB = parseDate(b.formatted_date);

      if (!dateA || !dateB) {
        return 0;
      }

      return dateB.getTime() - dateA.getTime();
    });
    return sortedList;
  }
  const sortedFilteredNotifications = handleSort(notificationBooking);
  const sortedNotificationGeneral = handleSort(notificationGeneral);
  const sortedAllNotificationList = handleSort(notificationList);

  return (
    <IonPage>
      {networkStatus == false && darkModeValue == "darkMode" &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="dark-mode-header-toolbar">
            <IonButtons slot="start">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/darkBackArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center dark-ion-title">Notifications</IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
              
              >
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
          <IonContent fullscreen className="notifications dark-mode-ion-content ion-padding">
            <IonSegment style={{ margin: "-16px 0px 0px 0px" }} className='fontName' value={segment} onIonChange={handleInputChange}>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="booking">
                <IonLabel>Booking</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="general">
                <IonLabel>General</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="all">
                <IonLabel>All</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/darkNoNetwork.svg"></IonImg>
              <h6 style={{ color: "#CCCCCC" }} className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent>
        </>
      }
      {networkStatus == true &&
        darkModeValue == "darkMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="dark-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/darkBackArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center dark-ion-title">Notifications</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                 
                >
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="notifications dark-mode-ion-content ion-padding">
            <IonSegment style={{ margin: "-16px 0px 0px 0px" }} className='fontName' value={segment} onIonChange={handleInputChange}>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="booking">
                <IonLabel>Booking</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="general">
                <IonLabel>General</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName dark-custom-segment-button-notification' value="all">
                <IonLabel>All</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            {segment === "booking" && (
              <IonRefresher slot="fixed" onIonRefresh={handleBookingsRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {segment === "general" && (
              <IonRefresher slot="fixed" onIonRefresh={handleGeneralRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {segment === "all" && (
              <IonRefresher slot="fixed" onIonRefresh={handleAllRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            <div className="section animate__animated animate__fadeInUp">
              {segment === "booking" && (
                <div>
                  {NotificationsAvail == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#CCCCCC",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {sortedFilteredNotifications.map((user, i) => (
                        <IonCol key={i} size-lg="4" size-md="6" size="12">
                          <IonGrid key={i}
                          onClick={() => { notificationReadStatus(user.id);
                            if (user.booking_id !== null) {
                               notificationRoute(user);
                            } 
                          }}
                          className={user.readflag == 1 ? 'dark-notificationunread' : 'dark-notificationread'}>
                            <IonRow>
                              <IonCol size-md="1" size="2">
                                <IonAvatar className="notification-img">
                                  {user.image == null || user.image == "" ? (
                                    <IonImg src="assets/imgs/images/no-image.jpg" alt="No Image" />
                                  ) : (
                                    <IonImg src={user.image} alt="User Image" />
                                  )}
                                </IonAvatar>
                              </IonCol>
                              <IonCol size-md="11" size="10">
                                <div className="notification-first-div">
                                  <div className="notification-first-div">
                                    <IonLabel className="dark-notification-msg">
                                      {user.notification_message}
                                    </IonLabel>
                                    <IonLabel className="dark-notification-date-time">
                                      {user.formatted_date}
                                    </IonLabel>
                                  </div>
                                </div>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {segment === "general" && (
                <div>
                  {NotificationsGeneralAvail == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#CCCCCC",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {sortedNotificationGeneral.map((user: any, i: any) => (
                        <IonCol key={i} size-lg="4" size-md="6" size="12">
                          <Link style={{ color: "#515151" }} className="link-decoration" onClick={() => { notificationReadStatus(user.id) }} to={{
                            pathname: '/notificationDetail',
                            state: { data: user }
                          }}>

                            <IonGrid key={i}
                              className={user.readflag == 1 ? 'dark-notificationunread' : 'notificationread'}
                              onClick={() => { notificationReadStatus(user.id) }}
                            >
                              <IonRow>
                                <IonCol size-md="1" size="2">
                                  <IonAvatar className="notification-img">
                                    {user.image == null || user.image == "" ? (
                                      <IonImg src="https://afh.cloud/asset/img/afh_icon_image.jpg" alt="No Image" />
                                    ) : (
                                      <IonImg src={user.image} alt="User Image" />
                                    )}
                                  </IonAvatar>
                                </IonCol>
                                <IonCol size-md="11" size="10">
                                  <div className="notification-first-div">
                                    <IonLabel className="dark-notification-msg">
                                      {user.notification_message}
                                    </IonLabel>
                                    <IonLabel className="dark-notification-date-time">
                                      {user.formatted_date}
                                    </IonLabel>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </Link>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {segment === "all" && (
                <div>
                  {NotificationsAllAvail == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#CCCCCC",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {sortedAllNotificationList.map((user: any, i: any) => (
                        <IonCol key={i} size-lg="4" size-md="6" size="12">
                          <IonGrid
                            className={user.readflag == 1 ? 'dark-notificationunread' : 'notificationread'}
                            onClick={() => {
                              if (user.booking_id === null) {
                                redirectND(user);
                              } else {
                                notificationReadStatus(user.id); notificationRoute(user);
                              }
                            }}>
                            <IonRow>
                              <IonCol size-md="1" size="2">
                                <IonAvatar className="notification-img">
                                  {user.image == null || user.image == "" ? (
                                    <IonImg src="assets/imgs/images/no-image.jpg" alt="No Image" />
                                  ) : (
                                    <IonImg src={user.image} alt="User Image" />
                                  )}
                                </IonAvatar>
                              </IonCol>
                              <IonCol size-md="11" size="10">
                                <div className="notification-first-div">
                                  <IonLabel className="dark-notification-msg">
                                    {user.notification_message}
                                  </IonLabel>
                                  <IonLabel className="dark-notification-date-time">
                                    {user.formatted_date}
                                  </IonLabel>
                                </div>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonModal isOpen={showSuspendedModal} id="dark-example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="dark-model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName dark-thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }}
                        className='fontName booking-details-btn'
                        onClick={() => setShowSuspendedModal(false)}
                        expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonToast
                  isOpen={showToast}
                  onDidDismiss={() => setShowToast(false)}
                  message={errorMessage}
                  duration={3000}
                  color="danger"
                />
              </IonContent>
            </IonModal>
          </IonContent>
        </>
      }
      {networkStatus == false && darkModeValue == "lightMode" &&
        <><IonHeader className="ion-no-border">
          <IonToolbar className="light-mode-header-toolbar">
            <IonButtons slot="start">
              <IonButton
                className="end-btn"
                onClick={goBack}
              >
                <IonIcon
                  className="fav header-icons"
                  src="assets/imgs/icons/backArrow.svg"
                  slot="end" />
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center notifications-title">Notifications</IonTitle>
            <IonButtons slot="end">
              <IonButton
                className="end-btn"
                routerLink="/login"
                routerDirection="none"
              >
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="notifications ion-padding">
            <IonSegment style={{ margin: "-16px 0px 0px 0px" }} className='fontName' value={segment} onIonChange={handleInputChange}>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="booking">
                <IonLabel>Booking</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="general">
                <IonLabel>General</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="all">
                <IonLabel>All</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <div className="no-network-div">
              <IonImg className="no-network-img" src="assets/imgs/images/noNetwork.svg"></IonImg>
              <h6 className='fontName'>Check your internet connection and try again</h6>
            </div>
          </IonContent></>
      }
      {networkStatus == true && darkModeValue == "lightMode" &&
        <>
          <IonHeader className="ion-no-border">
            <IonToolbar className="light-mode-header-toolbar">
              <IonButtons slot="start">
                <IonButton
                  className="end-btn"
                  onClick={goBack}
                >
                  <IonIcon
                    className="fav header-icons"
                    src="assets/imgs/icons/backArrow.svg"
                    slot="end"
                  />
                </IonButton>
              </IonButtons>
              <IonTitle className="ion-text-center notifications-title">Notifications</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  className="end-btn"
                  routerLink="/login"
                  routerDirection="none"
                >
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen className="notifications ion-padding">
            <IonSegment style={{ margin: "-16px 0px 0px 0px" }} className='fontName' value={segment} onIonChange={handleInputChange}>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="booking">
                <IonLabel>Booking</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="general">
                <IonLabel>General</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton className='fontName custom-segment-button-notification' value="all">
                <IonLabel>All</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            {segment === "booking" && (
              <IonRefresher slot="fixed" onIonRefresh={handleBookingsRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {segment === "general" && (
              <IonRefresher slot="fixed" onIonRefresh={handleGeneralRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            {segment === "all" && (
              <IonRefresher slot="fixed" onIonRefresh={handleAllRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>
            )}
            <div className="section animate__animated animate__fadeInUp">
              {segment === "booking" && (
                <div>
                  {NotificationsAvail == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {sortedFilteredNotifications.map((user, i) => (
                        <IonCol key={i} size-lg="4" size-md="6" size="12">
                          <IonGrid key={i}
                            onClick={() => { notificationReadStatus(user.id);
                              if (user.booking_id !== null) {
                                 notificationRoute(user);
                              } 
                            }}
                            className={user.readflag == 1 ? 'notificationunread' : 'notificationread'}
                          >
                            <IonRow>
                              <IonCol size-md="1" size="2">
                                <IonAvatar className="notification-img">
                                  {user.image == null || user.image == "" ? (
                                    <IonImg src="assets/imgs/images/no-image.jpg" alt="No Image" />
                                  ) : (
                                    <IonImg src={user.image} alt="User Image" />
                                  )}
                                </IonAvatar>
                              </IonCol>
                              <IonCol size-md="11" size="10">
                                <div className="notification-first-div">
                                  <IonLabel className="notification-msg">
                                    {user.notification_message}
                                  </IonLabel>
                                  <IonLabel className="notification-date-time">
                                    {user.formatted_date}
                                  </IonLabel>
                                </div>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {segment === "general" && (
                <div>
                  {NotificationsGeneralAvail == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {sortedNotificationGeneral.map((user: any, i: any) => (
                        <IonCol key={i} size-lg="4" size-md="6" size="12">
                          <Link style={{ color: "#515151" }} className="link-decoration" onClick={() => { notificationReadStatus(user.id) }} to={{
                            pathname: '/notificationDetail',
                            state: { data: user }
                          }}>

                            <IonGrid
                              className={user.readflag == 1 ? 'notificationunread' : 'notificationread'}
                              onClick={() => { notificationReadStatus(user.id) }}
                            >
                              <IonRow>
                                <IonCol size-md="1" size="2">
                                  <IonAvatar className="notification-img">
                                    {user.image == null || user.image == "" ? (
                                      <IonImg src="https://afh.cloud/asset/img/afh_icon_image.jpg" alt="No Image" />
                                    ) : (
                                      <IonImg src={user.image} alt="User Image" />
                                    )}
                                  </IonAvatar>
                                </IonCol>
                                <IonCol size-md="11" size="10">
                                  <div className="notification-first-div">
                                    <IonLabel className="notification-msg">
                                      {user.notification_message}
                                    </IonLabel>
                                    <IonLabel className="notification-date-time">
                                      {user.formatted_date}
                                    </IonLabel>
                                  </div>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          </Link>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
              {segment === "all" && (
                <div>
                  {NotificationsAllAvail == true && (
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <div className="fontName nocard">
                            <IonImg className="no-data-img" src="assets/imgs/images/no-data.svg"></IonImg>
                            <h6
                              style={{
                                color: "#515151",
                                textAlign: "center",
                                marginTop: "11%",
                              }}
                              className="fontName nocardh6"
                            >
                              Sorry, there is no Notifications for you
                            </h6>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  )}
                  <IonGrid>
                    <IonRow>
                      {sortedAllNotificationList.map((user: any, i: any) => (
                        <IonCol key={i} size-lg="4" size-md="6" size="12">
                          <IonGrid
                            className={user.readflag === 1 ? 'notificationunread' : 'notificationread'}
                            onClick={() => {
                              if (user.booking_id === null) {
                                redirectND(user);
                              } else {
                                notificationReadStatus(user.id); notificationRoute(user);
                              }
                            }}
                          >
                            <IonRow>
                              <IonCol size-md="1" size="2">
                                <IonAvatar className="notification-img">
                                  {user.image == null || user.image == "" ? (
                                    <IonImg src="assets/imgs/images/no-image.jpg" alt="No Image" />
                                  ) : (
                                    <IonImg src={user.image} alt="User Image" />
                                  )}
                                </IonAvatar>
                              </IonCol>
                              <IonCol size-md="11" size="10">
                                <div className="notification-first-div">
                                  <IonLabel className="notification-msg">
                                    {user.notification_message}
                                  </IonLabel>
                                  <IonLabel className="notification-date-time">
                                    {user.formatted_date}
                                  </IonLabel>
                                </div>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonCol>
                      ))}
                    </IonRow>
                  </IonGrid>
                </div>
              )}
            </div>
            <IonLoading
              isOpen={loading}
              spinner="circular"
              translucent={true}
            />
            <IonModal isOpen={showSuspendedModal} id="example-modal-confirmed" backdropDismiss={false}>
              <IonContent className="model-bg">
                <IonGrid className='cnfmModelGrid'>
                  <IonRow>
                    <IonCol size="12" col-12>
                      <IonImg src="assets/imgs/icons/warning.svg" alt="Product" className="tick-img"></IonImg>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName b-cnfmed-title'>{supendedTitle}</IonLabel>
                    </IonCol>
                    <IonCol size="12" col-12>
                      <IonLabel className='fontName thanks-cnfmed-title'>{supendedMsg}</IonLabel>
                    </IonCol>
                    <IonCol size='12' col-12>
                      <IonButton
                        routerLink="/login"
                        routerDirection="none"
                        style={{ width: "100%", margin: "auto" }} className='fontName booking-details-btn' onClick={() => setShowSuspendedModal(false)} expand="block">
                        Back to Login
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            </IonModal>
            <IonToast
              isOpen={showToast}
              onDidDismiss={() => setShowToast(false)}
              message={errorMessage}
              duration={3000}
              color="danger"
            />
          </IonContent>
        </>
      }
    </IonPage>
  );
};

export default Notifications;
